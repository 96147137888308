<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·采购偏好-更多弹窗
开始时间：2018-10-08
开发人员：陈立珍
最后修改：2018-10-12
备注说明：如需修改请联系开发人员
-->

<template>
	<div class="purchaseSearch">
		<el-dialog title="采购偏好" class='dialogOne' size="tiny" :visible.sync="test" :before-close="close" :modal="false" :modal-append-to-body="true" :lock-scroll="true" :close-on-click-modal="false">
			<popupLoading></popupLoading>
			<!-- 搜索 -->
			<div class="searchHead">
				<input v-model="purchaseInput" class="search-input" type="text" placeholder="请输入采购偏好..." />
				<span @click="searchClick" class="search-btn">搜索</span>
				<span @click="reset_purchaseInput" class="search-btn">重置</span>
				<el-checkbox v-model="checkedSure">精准</el-checkbox>
			</div>
			<!-- 表格 -->
			<div class="search-result">
				<table width="100%" cellspacing="0" v-loading="autographLoading" element-loading-text="拼命加载中">
					<thead>
						<tr>
							<th width="25%">序号</th>
							<th width="25%">采购偏好</th>
							<th width="15%">选择</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item,index) in purchaseInfos">
							<td>
								<span>{{index+1}}</span>
							</td>
							<td>
								<span>{{item.name}}</span>
							</td>
							<td>
								<el-radio-group v-model="choosePurchase">
									<el-radio class="radio2" :label="item.name"></el-radio>
								</el-radio-group>
							</td>
						</tr>
						<tr v-if="purchaseInfos.length == 0">
							<td colspan="100%" class="noData-box">
								<div class="noData-bg">
								</div>
								<span class="noData">暂无数据</span>
							</td>
						</tr>
					</tbody>
				</table>
				<!-- 分页 -->
				<div style="text-align: center;padding-top: 10px;">
					<el-pagination v-if="purchaseInfos.length > 0" :current-page="p_currpage" :page-size="p_pagesize" layout="total,sizes,prev, pager, next, jumper" :total="p_tolRecord" @current-change="p_changeCurrentPage" @size-change="p_changeSizePage">
					</el-pagination>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitPurchase()">确 定</el-button>
				<el-button @click="close">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import popupLoading from '@/components/publicComponent/popupLoading'
	export default({
		props: ['purchaseLists2','purchaseValue','acus_userids'],
		data() {
			return {
				autographLoading: false, //加载框
				test: true, //弹窗
				purchaseInput: '', //采购偏好搜索内容
				p_currpage: 1, //当前页
				p_pagesize: 10, //一页显示的条数
				p_tolRecord: 0, //总条数
				choosePurchase: '', //选中的采购偏好
				purchaseInfos: [], //采购偏好信息
				checkedSure:false,//精准
			}
		},
		components: {
			popupLoading
		},
		methods: {
			//触发父组件关闭弹窗
			close: function() {
				this.$emit('close', false);
			},
			//获取采购偏好数据集合
			getPurchaseInfo(){
				var lef = this;
				$.ajax({
					type: "POST",
					async: false,
					cache: false,
					url: backUrl + "cus_findCustomerIndustries.action",
					data: {
						token: token,
						currpage: lef.p_currpage,
						pagesize: lef.p_pagesize,
						value: lef.purchaseInput,
						userids: this.acus_userids
					},
					success: function(msg) {
						lef.purchaseInfos = msg.industries;
						lef.p_tolRecord = msg.pageBean.totalRecord;
					}
				});
			},
			//搜索采购偏好方法
			searchClick() {
				this.p_currpage = 1;
				this.p_pagesize = 10;
				//获取采购偏好
				this.getPurchaseInfo();
			},
			//重置搜索内容
			reset_purchaseInput() {
				this.purchaseInput = "";
				this.purchaseInfos = this.purchaseLists2.industries;
				this.p_tolRecord = this.purchaseLists2.pageBean.totalRecord;
			},
			//分页查询
			p_changeCurrentPage(page) {
				this.p_currpage = page;
				this.p_pagesize = 10;
				//获取采购偏好
				this.getPurchaseInfo();
			},
			//条数查询
			p_changeSizePage(size) {
				this.p_currpage = 1;
				this.p_pagesize = size;
				//获取采购偏好
				this.getPurchaseInfo();
			},
			//确认搜索采购偏好
			submitPurchase() {
				var isSure = 0;
				if(this.checkedSure){
					isSure = 1;
				}
				if(this.choosePurchase == ""){
					this.$message({
						showClose: true,
						message: '请选择采购偏好!',
						type: 'warning',
						duration: 2000
					});
					return;
				}
				var conditionvalue = isSure+"###"+this.choosePurchase
				this.$emit('purchaseByValue', conditionvalue);
			},
		},
		mounted: function() {
			this.purchaseInfos = this.purchaseLists2.industries;
			this.p_tolRecord = this.purchaseLists2.pageBean.totalRecord;
			var str = this.purchaseValue.split("###")[0];
			if(str == 1){
				this.checkedSure = true;
			}else{
				this.checkedSure = false;
			}
			this.choosePurchase = this.purchaseValue.split("###")[1];
			function clearTransform() {
				$('.el-dialog').css({
					'transform': 'none',
				})
			};
			dialogDrag();
			clearTransform(dialogCenter());
		}
	})
</script>

<style>
	.purchaseSearch .el-dialog {
		width: 680px;
		height: auto;
	}
	
	.purchaseSearch .el-dialog__body {
		height: 500px;
		padding: 1px 10px;
		text-align: left;
		clear: both;
		color: #48576a;
	}
	
	.searchHead .search-input {
		width: 250px;
		height: 30px;
		vertical-align: middle;
		border: 1px solid #ddd;
	}
	
	.searchHead .search-btn {
		cursor: pointer;
		display: inline-block;
		height: 30px;
		line-height: 30px;
		color: #fff;
		background-color: #20a0ff;
		vertical-align: middle;
		width: 50px;
		text-align: center;
	}
	
	.searchHead .search-btn:hover {
		background: #4db3ff;
		border-color: #4db3ff;
		color: #fff;
	}
	
	.search-result {
		margin-top: 10px;
	}
	
	.search-result th,
	.search-result td {
		text-align: center;
		font-weight: normal;
	}
	
	.search-result th {
		background-color: #ededed;
		border-bottom: 1px solid #ededed;
		height: 30px;
	}
	
	.search-result td {
		height: 50px;
		border-bottom: 1px solid #ededed;
	}
	
	.search-result td .el-radio__label {
		display: none;
	}
</style>