<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·添加新客户·导入客户结果弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<template>
  <div id="commonAlert">
      <el-dialog
        title="导入客户结果"
        class='yourName result-client dialogTwo'
        size="tiny"
        :visible.sync="test"
        :before-close="close"
        :modal="false"
        :modal-append-to-body="true"
        :lock-scroll="true"
        :close-on-click-modal="false">
        <div class="crm-al-list">
          <div>
            <table width="100%" cellpadding="0" cellspacing="0" border="0">
              <tbody>
              <tr>
                <td class="crm-records-title">
                  <b><span>导入结果</span></b>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="result-info">
            成功条数为
            <span class="red">{{myResult.success_num}}</span>,
            失败条数为
            <span class="red">{{myResult.fail_num}}</span>,
            重复条数为
            <span class="red">{{myResult.repeat_num}}</span>,
            总导入条数为
            <span class="red">{{myResult.total_num}}</span>,
            总剩余可新建客户数为
            <span class="blue">{{myResult.surplus_num}}</span>.
          </div>
            <table width="100%" cellpadding="0" cellspacing="0" border="0" 
            	v-loading.body="autographLoading" element-loading-text="拼命加载中">
              <tbody>
              <tr>
                <td class="crm-records-title">
                  <b><span>不合法数据列表</span></b>
                </td>
              </tr>
              </tbody>
            </table>
            <table width="100%" cellpadding="0" cellspacing="0" border="0" class="crm-records-tab">
              <thead>
              <tr>
                <th width="5%" height="40"></th>
                <th width="10%" height="40">行数</th>
                <th width="40%" height="40">失败原因</th>
                <th width="45%" height="40">记录详情</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="tab in dataLists">
                <td width="5%" height="40"></td>
                <td width="10%" height="40">{{tab.row_num}}</td>
                <td width="40%" height="40">{{tab.reason}}</td>
                <td width="45%" height="40">{{tab.record_info}}</td>
              </tr>
              </tbody>
            </table>
            <!--暂无数据-->
            <div class="myNoData" v-show='dataLists.length== 0'>
              <p><i class="icon iconfont icon-zanwushuju"></i></p>
              <span>暂无数据</span>
            </div>
             <!--分页-->
            <el-pagination v-show='dataLists.length> 0'
              class="paging" :page-count='tolpage'
              :current-page.sync="currpage"
              :page-size="10" :total="tolRecord" @current-change='getHandleResult()'
              layout="total,prev, pager, next">
            </el-pagination>
        </div>
        <div class="al-dialog-button">
          <!--<input type="button" class="al-dialog-cancel" value="关闭"  @click="close">-->
          <span slot="footer" class="dialog-footer close-btn">
            <el-button  @click="close">关闭</el-button>
          </span>
        </div>
      </el-dialog>
  <!--</div>-->
  </div>
</template>
<script>
export  default ({
  name:"commonAlert",
  data(){
    return {
      autographLoading:false,//true选择框显示
      currpage:1,//当前页
      tolpage:1,//总页数
      tolRecord:1,//总条数
      test:true,
      alertShow:false,
      dataLists:[],//数据集合
      myResult:{
        success_num:0,
        fail_num:0,
        repeat_num:0,
        total_num:0,
        surplus_num:"无限制",
      }
    }
  },
  methods:{
    close:function(){
      /*子组件方法放置父组件去调用*/
      this.$emit("closeAlert",false)
    },
    //处理结果
    getHandleResult() {
      let self = this;
      $.ajax({
        type: "POST",
        url: backUrl + "cus_findImportCustomersError.action",
        dataType: "json",
        data: {
          token: token,
          currpage: this.currpage,
          pagesize: 10,
          excelId: this.getupid
        },
        cache: false,
        async: false,
        beforeSend : function() {
          this.autographLoading=true;
        },
        complete : function() {
          this.autographLoading=false;
        },
        success: function(msg) {
          self.tolpage = msg.pageBean.totalPage; //总页数   
          self.tolRecord = msg.pageBean.totalRecord; //总条数
          self.myResult.success_num = msg.importCustomerResult.result.success_num;
          self.myResult.fail_num = msg.importCustomerResult.result.failure_num;
          self.myResult.repeat_num = msg.importCustomerResult.result.repeat_num;
          self.myResult.total_num = msg.importCustomerResult.result.total_num;
          if(msg.importCustomerResult.result.left_num == -1){
            self.myResult.surplus_num = "无限制";
          }else{
            self.myResult.surplus_num = msg.importCustomerResult.result.left_num;
          }
          self.dataLists = []
          $.each(msg.importCustomerResult.error_list, function(index, row) {
            self.dataLists.push({
              row_num: row.row,
              reason: row.reason,
              record_info: row.record_info
            })
          });
        }
      });
    }
  },
  props: ['getupid'],
  mounted(){
    this.getHandleResult();//加载数据
    function clearTransform(){
      $('.el-dialog').css({
        'transform': 'none'
      })
    };
    dialogDrag();
    clearTransform(dialogCenter());
  }
})
</script>
<style lang="less" scoped>

  .crm-al-list{
    overflow:auto;
    /*padding: 0 20px 10px 20px;*/
    height:350px;
    .result-info{
      font-size:12px;
      .red{
        color: red;
        font-size: 16px;
      }
      .blue{
        color: blue;
        font-size: 16px;
      }
    }
  }
  .al-dialog-button{
    text-align: right;
    margin-top: 10px;
    font-size: 12px;

    .al-dialog-cancel{
      border: 1px solid #999;
      height: 24px;
      line-height: 22px;
      color: #666;
      cursor: pointer;
      padding: 0 5px;
      margin: 0px;
      outline: none;
    }
    .close-btn{
      margin-right:0;
    }
  }
  .crm-records-tab{
    .crm-al-list{
      th{
        text-shadow: 1px 1px 1px #fff;
        background-color: #eef1f6;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        padding:10px 0;
        color: #000;
        font-weight:normal;
        text-align: left;
      }
    }
    th,td{
      font-size:12px;

    }
  }
</style>
