<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·主页面
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-12-06
备注说明：如需修改请联系开发人员
-->

<template>
  <div id="crm-content" class="crm-content">
    <!-- 顶部导航栏·位置 -->
    <div class="crm-content-title">
      <i class="icon iconfont icon-zhuye index-icon" @click="returnCrm"></i>
      <span class="crm-content-site">
        您的位置：{{myTitle.name}}
        <i class="refresh"></i>
      </span>
      <!--显示当前选择的国家信息-->
      <div v-show="checkListShow.length>0 " style="float:left">
        <div  style="display:flex;">
          <span class="countryLeft5">(</span>
        <div
          v-for="(row,rIndex) in checkListShow"
          :key="'country'+rIndex"
          v-show="rIndex<10"
          style="display:flex;">
          <span class="countryFlag countryLeft5" :class="row.class" :title="row.name"></span>
          <span class="countryLeft5" v-if="rIndex!=checkListShow.length-1">|</span>
        </div>
        <span
          v-show="checkListShow.length>10"
          class="countryLeft5"
          title="最多显示10个，其他国家的选择请在更多选项中查看"
        >...</span>
        <span class="countryLeft5">)</span>
        </div>
      </div>
      <!--引入搜索框-->
      <common-search
        @queryCustomerSimple="queryCustomerSimple"
        @queryCustomerHigh="queryCustomerHigh"
      ></common-search>
    </div>
    <div class="crm-content-opr">
      <!-- 操作 -->
      <div class="crm-content-opr-tip">
        <span>操作：</span>
        <span class="opr-btn" @click="newFollow">新建跟进</span>
        <span class="opr-btn" @click="updateLabel">标记为</span>
        <span class="opr-btn" @click="sendWebmail">发邮件</span>
        <span class="opr-btn" @click="sendYxymail">一键营销</span>
        <span class="opr-btn" @click="modifyClient">修改分类</span>
        <span class="opr-btn" @click="modifyAllERP()" v-if="showErp">一键ERP</span>
        <el-dropdown trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            更多操作
            <i class="el-icon-caret-bottom el-icon--right more_opr"></i>
          </span>
          <el-dropdown-menu slot="dropdown" class="op-dropdown more-opr">
            <el-dropdown-item command="a">
              <span>删除客户</span>
            </el-dropdown-item>
            <el-dropdown-item command="b" v-show="showOperation">
              <span>转移给</span>
            </el-dropdown-item>
            <el-dropdown-item command="c" v-show="showOperation">
              <span>共享给</span>
            </el-dropdown-item>
            <el-dropdown-item command="d" v-show="showOperation">
              <span>加入公海</span>
            </el-dropdown-item>
            <el-dropdown-item command="e" v-show="showSystemAdmin">
              <span>导出客户</span>
            </el-dropdown-item>
            <el-dropdown-item command="f">
              <span>锁定</span>
            </el-dropdown-item>
            <el-dropdown-item command="g">
              <span>解锁</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <br />
      <!-- 查看 -->
      <div class="crm-content-opr-tip">
        <span>查看：</span>
        <span class="opr-btn" @click="listFilter1" :class="filterNum.num==1?'selectFilter':''">本周录入</span>
        <span>|</span>
        <span class="opr-btn" @click="listFilter2" :class="filterNum.num==2?'selectFilter':''">本周联系</span>
        <span>|</span>
        <!--<span class="opr-btn" @click="listFilter3" :class="filterNum.num==3?'selectFilter':''">本月联系</span>-->
        <span
          class="opr-btn"
          @click="listFilter53"
          :class="filterNum.num==53?'selectFilter':''"
        >7天未跟进</span>
        <span>|</span>
        <span
          class="opr-btn"
          @click="listFilter4"
          :class="filterNum.num==4?'selectFilter':''"
        >30天未跟进</span>
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            高级查看
            <i class="el-icon-caret-bottom el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown" class="op-dropdown expert-opr">
            <el-dropdown-item>
              <span @click="showMenu(0)">录入时间</span>
            </el-dropdown-item>
            <el-dropdown-item>
              <span @click="showMenu(1)">联系时间</span>
            </el-dropdown-item>
            <el-dropdown-item>
              <span @click="showMenu(2)">客户关系</span>
            </el-dropdown-item>
            <el-dropdown-item>
              <span @click="showMenu(3)">客户来源</span>
            </el-dropdown-item>
            <el-dropdown-item>
              <span @click="showMenu(11)">所在国家</span>
            </el-dropdown-item>
            <el-dropdown-item>
              <span @click="showMenu(4)">客户星级</span>
            </el-dropdown-item>
            <el-dropdown-item v-show="showOperation">
              <span @click="showMenu(5)">我的公海</span>
            </el-dropdown-item>
            <el-dropdown-item>
              <span @click="showMenu(6)">成交记录</span>
            </el-dropdown-item>
            <el-dropdown-item>
              <span @click="showMenu(7)">成交金额</span>
            </el-dropdown-item>
            <el-dropdown-item>
              <span @click="showMenu(8)">入库方式</span>
            </el-dropdown-item>
            <el-dropdown-item>
              <span @click="showMenu(9)">关联产品</span>
            </el-dropdown-item>
            <el-dropdown-item>
              <span @click="showMenu(10)">采购偏好</span>
            </el-dropdown-item>
            <el-dropdown-item>
              <span @click="showMenu(12)">自定义标签</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <br />
      <div v-show="itemNum==0">
        <div class="crm-content-opr-tip">
          <span>录入时间</span>
          <span class="opr-btn condition-btn" @click="rightLoadCustomerList($event,46,0)">昨天</span>
          <span>
            <span>|</span>
            <span
              class="opr-btn condition-btn child-btn"
              @click="rightLoadCustomerList($event,45,0)"
            >最近7天</span>
          </span>
          <span>
            <span>|</span>
            <span
              class="opr-btn condition-btn child-btn"
              @click="rightLoadCustomerList($event,47,0)"
            >最近30天</span>
          </span>
          <span>
            <span>|</span>
            <span class="custom-date">自定义</span>
          </span>
        </div>
        <div class="search-bar">
          <el-date-picker
            class="search-input"
            v-model="dateValue"
            type="date"
            placeholder="开始时间"
            :editable="false"
            :clearable="false"
            :picker-options="pickerOptions0"
          ></el-date-picker>
          <span>-</span>
          <el-date-picker
            class="search-input"
            v-model="dateValue2"
            type="date"
            placeholder="结束时间"
            :editable="false"
            :clearable="false"
            :picker-options="pickerOptions0"
          ></el-date-picker>
          <span class="search-btn" @click="dateSearch">查询</span>
        </div>
      </div>
      <div v-show="itemNum==1">
        <div class="crm-content-opr-tip">
          <span>联系时间</span>
          <span class="opr-btn condition-btn" @click="rightLoadCustomerList($event,14,0)">今天</span>
          <span>
            <span>|</span>
            <span
              class="opr-btn condition-btn child-btn"
              @click="rightLoadCustomerList($event,15,0)"
            >本周</span>
          </span>
          <span>
            <span>|</span>
            <span
              class="opr-btn condition-btn child-btn"
              @click="rightLoadCustomerList($event,16,0)"
            >本月</span>
          </span>
          <span>
            <span>|</span>
            <span
              class="opr-btn condition-btn child-btn"
              @click="rightLoadCustomerList($event,17,0)"
            >上月</span>
          </span>
          <span>
            <span>|</span>
            <span
              class="opr-btn condition-btn child-btn"
              @click="rightLoadCustomerList($event,18,0)"
            >最近三个月</span>
          </span>
          <span>
            <span>|</span>
            <span class="custom-date">自定义</span>
          </span>
        </div>
        <div class="search-bar">
          <el-date-picker
            class="search-input"
            v-model="dateValue3"
            type="date"
            placeholder="开始时间"
            :editable="false"
            :clearable="false"
            :picker-options="pickerOptions0"
          ></el-date-picker>
          <span>-</span>
          <el-date-picker
            class="search-input"
            v-model="dateValue4"
            type="date"
            placeholder="结束时间"
            :editable="false"
            :clearable="false"
            :picker-options="pickerOptions0"
          ></el-date-picker>
          <span class="search-btn" @click="dateSearch2">查询</span>
        </div>
      </div>
      <div v-show="itemNum==2">
        <div class="crm-content-opr-tip">
          <span>客户关系</span>
          <span class="opr-btn condition-btn" @click="rightLoadCustomerList($event,25,0)">无</span>
          <span v-for="row in customerrelationall">
            <span>|</span>
            <span
              class="opr-btn condition-btn child-btn"
              @click="rightLoadCustomerList($event,25,row.id)"
            >{{row.name}}</span>
          </span>
        </div>
      </div>
      <div v-show="itemNum==3">
        <div class="crm-content-opr-tip">
          <span>客户来源</span>
          <span class="opr-btn condition-btn" @click="rightLoadCustomerList($event,26,0)">无</span>
          <span v-for="row in customerfromall">
            <span>|</span>
            <span
              class="opr-btn condition-btn child-btn"
              @click="rightLoadCustomerList($event,26,row.id)"
            >{{row.name}}</span>
          </span>
        </div>
      </div>
      <div v-show="itemNum==12">
        <div class="crm-content-opr-tip">
          <span>自定义标签</span>
          <span class="opr-btn condition-btn" @click="rightLoadCustomerList($event,57,0)">无</span>
          <span v-for="row in pmail_userlablenum">
            <span>|</span>
            <span
              class="opr-btn condition-btn child-btn"
              @click="rightLoadCustomerList($event,57,row.mail_tips_id)"
            >{{row.mail_tips}}</span>
          </span>
        </div>
      </div>
      <div v-show="itemNum==4">
        <div class="crm-content-opr-tip">
          <span>客户星级</span>
          <span class="opr-btn condition-btn" @click="rightLoadCustomerList($event,37,0)">无星级</span>
          <span>
            <span>|</span>
            <span
              class="opr-btn condition-btn child-btn"
              @click="rightLoadCustomerList($event,37,1)"
            >一星</span>
          </span>
          <span>
            <span>|</span>
            <span
              class="opr-btn condition-btn child-btn"
              @click="rightLoadCustomerList($event,37,2)"
            >二星</span>
          </span>
          <span>
            <span>|</span>
            <span
              class="opr-btn condition-btn child-btn"
              @click="rightLoadCustomerList($event,37,3)"
            >三星</span>
          </span>
          <span>
            <span>|</span>
            <span
              class="opr-btn condition-btn child-btn"
              @click="rightLoadCustomerList($event,37,4)"
            >四星</span>
          </span>
          <span>
            <span>|</span>
            <span
              class="opr-btn condition-btn child-btn"
              @click="rightLoadCustomerList($event,37,5)"
            >五星</span>
          </span>
        </div>
      </div>
      <div v-show="itemNum==5">
        <div class="crm-content-opr-tip">
          <span>我的公海</span>
          <span
            class="opr-btn condition-btn"
            @click="rightLoadCustomerList($event,48,0)"
          >7天内未联系客户入公海库</span>
          <span>
            <span>|</span>
            <span
              class="opr-btn condition-btn child-btn"
              @click="rightLoadCustomerList($event,49,0)"
            >7天内未成交客户入公海库</span>
          </span>
          <span>
            <span>|</span>
            <span
              class="opr-btn condition-btn child-btn"
              @click="rightLoadCustomerList($event,50,0)"
            >7天内成交未联系客户入公海库</span>
          </span>
        </div>
      </div>
      <div v-show="itemNum==6">
        <div class="crm-content-opr-tip">
          <span>成交记录</span>
          <span class="opr-btn condition-btn" @click="rightLoadCustomerList($event,40,0)">未成交</span>
          <span>
            <span>|</span>
            <span
              class="opr-btn condition-btn child-btn"
              @click="rightLoadCustomerList($event,40,1)"
            >5单内</span>
          </span>
          <span>
            <span>|</span>
            <span
              class="opr-btn condition-btn child-btn"
              @click="rightLoadCustomerList($event,40,2)"
            >10单内</span>
          </span>
          <span>
            <span>|</span>
            <span class="custom-date">指定成交记录数</span>
          </span>
        </div>
        <div class="search-bar">
          <input type="number" v-model="numValue" class="search-records-input" placeholder="最少" />
          <input type="number" v-model="numValue2" class="search-records-input" placeholder="最多" />
          <span class="search-btn" @click="numSearch">查询</span>
        </div>
      </div>
      <div v-show="itemNum==7">
        <div class="crm-content-opr-tip">
          <span class="money_title">指定成交金额数</span>
        </div>
        <div class="search-bar money-search">
          <input type="number" v-model="numValue3" class="search-records-input" placeholder="最少" />
          <input type="number" v-model="numValue4" class="search-records-input" placeholder="最多" />
          <span class="search-btn" @click="numSearch2">查询</span>
        </div>
      </div>
      <div v-show="itemNum==8">
        <div class="crm-content-opr-tip">
          <span>入库方式</span>
          <span class="opr-btn condition-btn" @click="rightLoadCustomerList($event,42,1)">手工创建</span>
          <span>
            <span>|</span>
            <span
              class="opr-btn condition-btn child-btn"
              @click="rightLoadCustomerList($event,42,2)"
            >普通导入</span>
          </span>
          <span>
            <span>|</span>
            <span
              class="opr-btn condition-btn child-btn"
              @click="rightLoadCustomerList($event,42,3)"
            >系统导入</span>
          </span>
          <span>
            <span>|</span>
            <span
              class="opr-btn condition-btn child-btn"
              @click="rightLoadCustomerList($event,42,4)"
            >联系人转为</span>
          </span>
          <span>
            <span>|</span>
            <span
              class="opr-btn condition-btn child-btn"
              @click="rightLoadCustomerList($event,42,5)"
            >别人转移</span>
          </span>
          <span>
            <span>|</span>
            <span
              class="opr-btn condition-btn child-btn"
              @click="rightLoadCustomerList($event,42,6)"
            >回收站转移</span>
          </span>
          <span>
            <span>|</span>
            <span
              class="opr-btn condition-btn child-btn"
              @click="rightLoadCustomerList($event,42,7)"
            >回收站恢复</span>
          </span>
          <span>
            <span>|</span>
            <span
              class="opr-btn condition-btn child-btn"
              @click="rightLoadCustomerList($event,42,8)"
            >公海库领取</span>
          </span>
          <span>
            <span>|</span>
            <span
              class="opr-btn condition-btn child-btn"
              @click="rightLoadCustomerList($event,42,9)"
            >收邮件保存</span>
          </span>
          <span>|</span>
          <span
            class="opr-btn condition-btn child-btn"
            @click="rightLoadCustomerList($event,42,12)"
          >阿里询盘</span>
          <span>|</span>
          <span
            class="opr-btn condition-btn child-btn"
            @click="rightLoadCustomerList($event,42,13)"
          >官网注册</span>
          <span>|</span>
          <span
            class="opr-btn condition-btn child-btn"
            @click="rightLoadCustomerList($event,42,14)"
          >社交平台</span>
        </div>
      </div>
      <div v-show="itemNum==9">
        <div class="crm-content-opr-tip" style="display:flex;align-items:center">
          <span style="margin-right:8px;">关联产品</span>
          <el-checkbox-group v-model="checkList2" @change="getProductValue">
            <div style="display:flex;">
            		<el-checkbox label="0" class="elCountryC" style="display:flex;align-items:center">
            			<span style="color: #20a0ff;margin-left: 5px;" title="无">无</span>
            		</el-checkbox>
                <el-checkbox v-for="(row,rowIndex) in pageProductList" :key="rowIndex" :label="row.id" class="elCountryC" style="display:flex;align-items:center">
                  <span v-if="row.name.length > 18" style="color: #20a0ff;margin-left: 5px;" :title="row.name">{{row.name.substring(0,18)}}...</span>
                  <span v-else style="color: #20a0ff;margin-left: 5px;" :title="row.name">{{row.name}}</span>
                </el-checkbox>
            </div>
          </el-checkbox-group>
          <span style="margin-left: 10px;" class="crm-country-more" @click="cancelProductCheck">清除选项</span>
          <span
          	style="margin-left: 10px;"
            class="crm-country-more"
            @click="purchaseMore($event,1)"
            v-if="mainProductInfo.length >= 5"
          >更多</span>
        </div>
      </div>
      <div v-show="itemNum==10">
        <div class="crm-content-opr-tip">
          <span>采购偏好</span>
          <span v-for="row in purchaseLists" v-if="purchaseLists.length != 0">
            <span
              class="opr-btn condition-btn child-btn"
              @click="rightLoadCustomerList($event,52,row.name)"
            >{{row.name}}</span>
            <span>|</span>
          </span>
          <span
            class="opr-btn condition-btn"
            @click="purchaseMore($event,2)"
            v-if="purchaseLists.length != 0"
          >更多</span>
          <span class="opr-btn condition-btn" v-if="purchaseLists.length == 0">没有</span>
        </div>
      </div>
      <div v-show="itemNum==11">
        <div class="crm-content-opr-tip" style="display:flex;align-items:center">
          <span style="margin-right:8px;">所在国家</span>
          <el-checkbox-group v-model="checkList" @change="getCountryValue" @click="clickC()">
            <div style="display:flex;">
              <p
                v-for="(row,rowIndex ) in $store.state.countryArr"
                :key="'country'+rowIndex"
                v-show="rowIndex< 10"
                class="crm-country"
              >
                <el-checkbox :label="row.name" class="elCountryC" style="display:flex;">
                  <span class="countryFlag" :class="row.class" :title="row.name"></span>
                </el-checkbox>
              </p>
            </div>
          </el-checkbox-group>
          <span class="crm-country-more" @click="cancelCountryCheck">清除选项</span>
          <span class="crm-country-more" @click="lookMoreCountry">更多</span>
        </div>
      </div>
    </div>
    <!-- 表格 -->
    <div class="crm-table w100">
      <div class="w100">
        <table width="100%" cellpadding="0" cellspacing="0">
          <thead>
            <tr>
              <th height="30" class="th" style="width: 10px; padding: 10px 0px;"></th>
              <th height="30" class="th" style="width: 40px;">
                <el-checkbox @change="selectAll($event)" class="selectButton"></el-checkbox>
              </th>
              <th height="30" class="th select-down-box" width="90">
                <select class="dropMenu" v-model="processid">
                  <option title="所有" value="-1">所有</option>
                  <option
                    v-for="row in salesProcessList"
                    :value="row.id"
                    :title="row.name"
                  >{{row.name1}}</option>
                  <option title="无" value="0">无</option>
                </select>
              </th>
              <th height="30" class="th"  width="310">
                <span>企业信息</span>
                <i class="icon iconfont icon-paixu1 sortIcon"
                  title="按企业信息排序" @click="customerSort(6)" v-show="sort_tag != 6 && sort_tag != 7">
                </i>
                <i class="icon iconfont icon-shengxu sortIcon"
                  title="降序" @click="customerSort(6)" v-show="sort_tag == 7">
                </i>
                <i class="icon iconfont icon-jiangxu sortIcon"
                  title="升序" @click="customerSort(7)" v-show="sort_tag == 6">
                </i>
              </th>
              <th height="30" class="th" width="180">
                <span>客户编号</span>
              </th>
              <th height="30" class="th" width="100">
                <span>所在国家</span>
              </th>
              <th height="30" class="th" width="130">
                <div>
                  <span>客户星级</span>
                  <i class="icon iconfont icon-paixu1 sortIcon"
                    title="按客户星级排序" @click="customerSort(4)" v-show="sort_tag != 4 && sort_tag != 5">
                  </i>
                  <i class="icon iconfont icon-shengxu sortIcon"
                    title="降序" @click="customerSort(4)" v-show="sort_tag == 5">
                  </i>
                  <i class="icon iconfont icon-jiangxu sortIcon"
                    title="升序" @click="customerSort(5)" v-show="sort_tag == 4">
                  </i>
                </div>
              </th>
              <th width="130" height="30" class="th">
                <div>
                  <span>入库时间</span>
                  <i class="icon iconfont icon-paixu1 sortIcon"
                    title="按入库时间排序" @click="customerSort(0)" v-show="sort_tag != 0 && sort_tag != 1">
                  </i>
                  <i class="icon iconfont icon-shengxu sortIcon"
                    title="降序" @click="customerSort(0)" v-show="sort_tag == 1">
                  </i>
                  <i class="icon iconfont icon-jiangxu sortIcon"
                    title="升序" @click="customerSort(1)" v-show="sort_tag == 0">
                  </i>
                </div>
              </th>
              <th width="130" height="30" class="th">
                <div>
                  <span>最后联系时间</span>
                  <i class="icon iconfont icon-paixu1 sortIcon"
                    title="按最后联系时间排序" @click="customerSort(2)" v-show="sort_tag != 2 && sort_tag != 3">
                  </i>
                  <i class="icon iconfont icon-shengxu sortIcon"
                    title="降序" @click="customerSort(2)" v-show="sort_tag == 3">
                  </i>
                  <i class="icon iconfont icon-jiangxu sortIcon"
                    title="升序" @click="customerSort(3)" v-show="sort_tag == 2">
                  </i>
                </div>
              </th>
              <th width="18" class="th" v-show="customerList.length>9" style="padding: 8px 0;"></th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="w100 newTableStyle" style="top: 160px;">
        <table
          width="100%"
          cellpadding="0"
          cellspacing="0"
          v-loading="autographLoading"
          element-loading-text="拼命加载中"
        >
          <tbody>
            <tr v-for="(td,index) in customerList" class="_tr">
              <td class="td" height="50" style="width: 10px; padding: 10px 0px;"></td>
              <td class="td" width="40">
                <el-checkbox
                  v-model="selectArr"
                  :label="td.customer_id"
                  :key="index"
                  @change="selectOne($event)"
                  class="select_one"
                  :starLevel="td.important_star"
                  :partnership="td.partnership"
                  :calling="td.calling"
                  :classifyId="td.classify_id"
                  :customerStatus="td.customer_status"
                  :sourceFromid="td.source_from_id"
                  :prductId="td.prductid"
                ></el-checkbox>
              </td>
              <td class="td" width="100">
                <div style="margin-left: 20px;">
                  <span
                    class="course"
                    :title="td.process_name"
                    v-if="td.process_name=='无'"
                  >{{td.process_name2}}</span>
                  <span
                    class="course course-bao"
                    :title="td.process_name"
                    v-else-if="td.process_name=='报价'"
                  >{{td.process_name2}}</span>
                  <span
                    class="course course-yang"
                    :title="td.process_name"
                    v-else-if="td.process_name=='样品'"
                  >{{td.process_name2}}</span>
                  <span
                    class="course course-zi"
                    :title="td.process_name"
                    v-else-if="td.process_name=='咨询'"
                  >{{td.process_name2}}</span>
                  <span
                    class="course course-ding"
                    :title="td.process_name"
                    v-else-if="td.process_name=='订单'"
                  >{{td.process_name2}}</span>
                  <span
                    class="course course-xun"
                    :title="td.process_name"
                    v-else-if="td.process_name=='询盘'"
                  >{{td.process_name2}}</span>
                  <span
                    class="course course-other"
                    :title="td.process_name"
                    v-else
                  >{{td.process_name2}}</span>
                </div>
              </td>
              <td class="td  "  width="310" >
                <div class="disFlex">
                  <img :src="td.img_url" alt="客户头像" class="user-bg" />
                  <span class="user-info">
                    <!--滑过标题出现提示信息-->
                    <el-tooltip :content="cusDynamic" placement="right-start" effect="light">
                      <p
                        @mouseover="getFirstDynamic(td.customer_id)"
                        class="crm-infoTitle"
                        @click="openCustomerInfo(td.customer_id,td.user_id)"
                      >{{td.customer_name}}
                      <span
                  			class="labelTag"
                  			:title="td.labelName"
                  			:style="{'background-color': td.labelColor}">
                				{{td.labelName}}
                  		</span>
                  		</p>
                    </el-tooltip>
                    <br />
                    <p
                      class="crm-infoEmail"
                      @click="clickMessage(td.link_email,td.customer_id)"
                      title="点击快捷写信"
                    >{{td.link_email}}</p>
                  </span>
                </div>
              </td>
              <td class="td" width="180">
                <span class="width280" v-if="!td.userEdit" :title="td.userCode">{{td.userCode}}</span>
                <span
                  class="countryPoint width280"
                  v-else
                  :title="userCodeType?'立即生成':'点击进行填写'"
                  @click="editUserCode(td.customer_id)"
                >{{td.userCode}}</span>
              </td>
              <td class="td countryPoint" width="100">
                <span
                  class="countryFlag"
                  :class="td.countryClass"
                  v-if="td.countryClass!=''"
                  :title="td.country"
                  @click="editCountryInfo(td.country,td.customer_id)"
                ></span>
                <span
                  v-if="td.countryClass==''"
                  title="点击进入修改所在国家"
                  @click="editCountryInfo('',td.customer_id)"
                >无</span>
              </td>
              <td class="td" width="130">
                <div>
                  <span v-if="td.important_star==1">
                    <b class="star"></b>
                  </span>
                  <span v-else-if="td.important_star==2">
                    <b class="star"></b>
                    <b class="star"></b>
                  </span>
                  <span v-else-if="td.important_star==3">
                    <b class="star"></b>
                    <b class="star"></b>
                    <b class="star"></b>
                  </span>
                  <span v-else-if="td.important_star==4">
                    <b class="star"></b>
                    <b class="star"></b>
                    <b class="star"></b>
                    <b class="star"></b>
                  </span>
                  <span v-else-if="td.important_star==5">
                    <b class="star"></b>
                    <b class="star"></b>
                    <b class="star"></b>
                    <b class="star"></b>
                    <b class="star"></b>
                  </span>
                  <span v-else>暂无星级</span>
                </div>
              </td>
              <td width="130" class="td">
                <div>
                  <span :title="td.enrol_time">{{td.enrol_date}}</span>
                </div>
              </td>
              <td width="130" class="td">
                <div>
                  <span :title="td.contact_time">{{td.contact_date}}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="myNoData" v-show="customerList.length == 0">
          <p>
            <i class="icon iconfont icon-zanwushuju"></i>
          </p>
          <span>暂无数据</span>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="newPagingStyle" v-if="customerList.length>0">
      <el-pagination
        class="crmCCenter"
        :current-page.sync="cus_currpage"
        :page-sizes="[10, 20, 30, 40,50,60,70,80,90,100]"
        :page-size="cus_pagesize"
        :total="cus_tolRecord"
        layout="total,sizes, prev, pager, next, jumper"
        :page-count="cus_tolpage"
        @size-change="siezChange"
        @current-change="loadCustomerList()"
      ></el-pagination>
    </div>
    <!--共享弹框-->
    <small-layer
      class="smallLayer"
      v-if="showShare"
      @cntClose="showShare = false"
      @cntConfirm="cntConfirmShare"
      :client-list="clientList"
      :my-index="clientList.value"
      :connect-tab="false"
      :showType="2"
      cnt-title="共享给"
      :has-button="true"
    ></small-layer>
    <!--转移弹框-->
    <small-layer
      class="smallLayer"
      v-if="showC"
      @cntClose="showC = false"
      @cntConfirm="cntConfirm"
      :client-list="clientList"
      :showType="1"
      :my-index="clientList.value"
      :connect-tab="false"
      :is-course="true"
      cnt-title="转移给"
      :has-button="true"
    ></small-layer>
    <!--修改分类弹框-->
    <small-layer
      class="modifyLayer"
      v-if="showM"
      @cntClose="cntCloseUpdate()"
      @cntConfirm="cntConfirm2"
      :client-list="false"
      :connect-tab="false"
      :hasRating="true"
      cnt-title="修改分类"
      :showType="3"
      :has-button="true"
      :has-rating="true"
      :star="starlevel"
      :type="classifyid"
      :froms="sourceid"
      :relat="relationid"
      :status="statusid"
      :hangye="hangyeid"
      :product="productid"
      :chooseProId="productid"
    ></small-layer>
    <!--新建跟进弹出框-->
    <createFollow
      v-if="showF"
      @close="showF=false"
      :customerids="customerids"
      :userId="userId"
      @addRecord="addRecord"
    ></createFollow>
    <!--客户详情弹出框-->
    <moreInfoEdit
      v-if="showN"
      :isOnlyRead="false"
      @refreshCusList="loadCustomerList"
      @all_changeState="all_changeState"
      :customer_id="customer_id"
      :userId="userId"
      @closeInfo="showN = false"
      :has-editbtn="hasEditbtn"
    ></moreInfoEdit>
    <!--快捷写信-->
    <writeMessage
      @closeMessage="closeMessage"
      @writeMessage="writeMessages"
      v-if="showMessage"
      :my-email="myEmail"
      :cusorlink="1"
      :id="customer_id"
      :cusId="customer_id"
    ></writeMessage>

    <!--采购偏好更多-->
    <purchaseSearch
      v-if="showPurchase"
      @close="showPurchase=false"
      :purchaseLists2="purchaseLists2"
      :purchaseValue="purchaseValue"
      @purchaseByValue="purchaseByValue"
    ></purchaseSearch>

    <!--选择销售进程弹出框-->
    <el-dialog
      title="请选择销售进程"
      :visible.sync="yxyProcess"
      size="tiny"
      class="acus-popup1 dialogOne"
      :close-on-click-modal="false"
      :modal="false"
    >
      <div>
        <el-radio-group v-model="yxyProcessId">
          <ul>
            <li class="acus-process" v-for="row in salesProcessList">
              <el-radio :label="row.id">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :open-delay="300"
                  :content="row.name"
                  placement="right"
                >
                  <span>{{row.name2}}</span>
                </el-tooltip>
              </el-radio>
            </li>
          </ul>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="yxyProcessConfirm">确 定</el-button>
        <el-button @click="yxyProcess = false">取 消</el-button>
      </span>
    </el-dialog>

    <!--选择自定义标签弹出框-->
    <el-dialog
      title="请选择自定义标签"
      :visible.sync="showLabel"
      size="tiny"
      class="acus-popup1 dialogOne"
      :close-on-click-modal="false"
      :modal="false">
      <div>
        <el-radio-group v-model="labelId">
          <ul>
            <li class="acus-process" v-for="row in pmail_userlablenum">
              <el-radio :label="row.mail_tips_id">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :open-delay="300"
                  :content="row.mail_tips"
                  placement="right">
                  <span>{{row.mail_tips}}</span>
                </el-tooltip>
              </el-radio>
            </li>
          </ul>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="labelConfirm">确 定</el-button>
        <el-button @click="showLabel = false">取 消</el-button>
      </span>
    </el-dialog>

    <!--编辑所在国家-->
    <el-dialog
      title="编辑所在国家"
      :visible.sync="editcountryBox"
      size="tiny"
      class="dialogOne editCountryEl"
      :close-on-click-modal="false"
      :modal="false"
      :before-close="reduceCountry"
    >
      <div style="display:flex;position:relative;margin-top:10px;align-items: center;">
        <span style="margin-right:5px;">所在国家</span>
        <all-country v-if="editcountryBox"></all-country>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmCountry">确 定</el-button>
        <el-button @click="reduceCountry">取 消</el-button>
      </span>
    </el-dialog>

    <!--查看所在国家-->
    <el-dialog
      title="查看所在国家"
      :visible.sync="lookCountryBox"
      size="tiny"
      class="dialogOne editCountryEl"
      :close-on-click-modal="false"
      :modal="false"
      :before-close="reduceCountry2"
    >
      <div class="crm-content-opr-tip crm-country-opr">
        <el-checkbox-group v-model="lookCheckList">
          <div>
            <p
              v-for="(row,rowIndex ) in $store.state.countryArr"
              :key="'country'+rowIndex"
              class="crm-country2"
              :title="row.name"
            >
              <el-checkbox
                :label="row.name"
                style="display:flex;align-items:center;height:24px;"
                class="elCountryC"
              >
                <span class="countryFlag" :class="row.class"></span>
                <span class="countryName">{{row.name}}</span>
              </el-checkbox>
            </p>
          </div>
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmCountry2">确 定</el-button>
        <el-button @click="reduceCountry2">取 消</el-button>
      </span>
    </el-dialog>

    <mainProduct
      v-if="mainShow"
      @closePro="mainShow = false"
      @submitProBtn="submitProBtn"
      :major_productall="majorproductall"
      :mainProId="mainProId"
    ></mainProduct>

    <!-- 查看关联产品 -->
    <el-dialog
    	title="选择关联产品"
      :visible.sync="showProductMore"
      size="tiny"
      class="dialogOne productDialog"
      :close-on-click-modal="false"
      :modal="false"
      :before-close="closeProductDialog">
      <!-- 搜索 -->
      <div class="searchHead">
        <input v-model="p_input" class="search-input" type="text" placeholder="根据产品名称、产品编号搜索" />
        <span @click="searchProClick" class="search-btn">搜索</span>
        <span @click="reset_proInput" class="search-btn">重置</span>
      </div>
      <!-- 表格 -->
      <div class="search-result">
        <el-row class="userBoxHead">
          <el-col :span="2">
            <div class="grid-content">
              <el-checkbox v-model="allProduct" title="全选" @change="getAllProductInfo">&nbsp;</el-checkbox>
            </div>
          </el-col>
          <el-col :span="14">
            <div class="grid-content">
              <span>产品名称</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              <span>产品编号</span>
            </div>
          </el-col>
        </el-row>
        <el-checkbox-group v-model="checkList3">
          <el-row
            class="userBoxList"
            v-for="(elem,index) in mainProductInfo"
            :key="'product'+index"
            v-show="mainProductInfo.length>0">
            <el-col :span="2">
              <div class="grid-content bg-purple">
                <el-checkbox :label="elem.id">&nbsp;</el-checkbox>
              </div>
            </el-col>
            <el-col :span="14">
              <div class="grid-content">
                <span :title="elem.name">{{elem.name | getValue}}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="grid-content">
                <span :title="elem.product_code">{{elem.product_code | getValue}}</span>
              </div>
            </el-col>
          </el-row>
          <el-row v-show="mainProductInfo.length==0">
            <el-col :span="24">
              <div class="grid-content noData">
                <span >暂无数据</span>
              </div>
            </el-col>
          </el-row>
        </el-checkbox-group>
      </div>
       <div style="text-align: center;padding-top: 10px;">
          <el-pagination
            v-if="mainProductInfo.length > 0"
            :current-page="p_currpage"
            :page-size="p_pagesize"
            layout="sizes, prev, pager, next"
            :total="p_tolRecord"
            @current-change="p_changeCurrentPage"
            @size-change="p_changeSizePage"
            :pager-count="pageCount"
          ></el-pagination>
        </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitProInfo()">确 定</el-button>
        <el-button @click="closeProductDialog">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import commonSearch from "../CommonItem/commonSearch.vue";
import smallLayer from "../CommonItem/smallLayer.vue";
import createFollow from "./allClient-createFollow.vue";
import moreInfoEdit from "../CommonItem/crmMoreInfo_Editor.vue";
import writeMessage from "../CommonItem/writeMessage.vue";
import purchaseSearch from "../CommonItem/purchaseSearch.vue";
import mainProduct from "../CommonItem/layer_mainProduct.vue";
import store from "../../../vuex/store.js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import "../../../vuex/mutations.js";
import allCountry from "@/components/crm/country/country";
export default {
  name: "crmContent",
  store,
  data() {
    return {
      cus_currpage: 1, //客户例表当前页
      cus_pagesize: 10, //客户例表每页显示数量
      cus_tolpage: 1, //客户例表总页数
      cus_tolRecord: 1, //总条数
      customerList: [], //客户列表集合
      sort_tag: 0,//排序标识
      autographLoading: false, //加载框
      cuscondition: 0, //右侧子类查询标识
      cusconditionvalue: 0, //右侧子类查询值
      selectArr: [], //选择的客户,客户id,邮件地址,是否共享
      processid: -1, //当前选择的进程id
      dateValue: "", //录入时间-开始时间
      dateValue2: "", //录入时间-结束时间
      dateValue3: "", //联系时间-开始时间
      dateValue4: "", //联系时间-结束时间
      numValue: 1, //成交记录-最少
      numValue2: 5, //成交记录-最多
      numValue3: 100, //成交金额-最少
      numValue4: 10000, //成交金额-最多
      showOperation: false, //是否显示转移客户|共享客户|加入公海操作,false隐藏,true显示
      cusDynamic: "正在加载...", //当前客户的最新动态
      showF: false, //新建跟进弹出框标识
      showShare: false, //共享给弹出框标识
      showC: false, //转移给弹出框标识
      clientList: [], //转移|共享的用户列表
      showM: false, //修改分类弹出框标识
      classifyid: 0, //当前客户分类id
      sourceid: 0, //当前客户来源id
      relationid: 0, //当前客户关系id
      statusid: 0, //当前客户状态id
      hangyeid: 0, //当前客户行业id
      productid: 0, //当前客户产品id
      starlevel: 0, //客户星级
      chanceid: 0, //商机id
      saleid: 0, //订单id
      customerids: "", //客户id,多个以逗号分割
      customer_id: 0, //客户id
      userId: 0, //用户id
      yxyProcess: false, //群发选择销售进程弹出框
      yxyProcessId: 0, //群发选择销售进程id
      myEmail: "",
      checkAll: true,
      itemNum: -1,
      showN: false,
      showMessage: false, //写信弹窗
      pickerOptions1: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            }
          }
        ]
      },
      editClassifyId: 0, //修改分类时分类id
      editStatusId: 0, //修改分类时的状态id
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      salesProcessList: [], //销售进程
      hasEditbtn: false,

      //修改分类
      chooseProId: 0, //当前客户产品id

      showPurchase: false, //采购偏好-更多
      purchaseValue: "", //采购偏好选中的值
      purchaseLists: [], //采购偏好集合
      purchaseLists2: [], //采购偏好集合
      showSystemAdmin: false, //只有系统管理员才能显示(导出客户)

      mainShow: false, //关联产品弹窗
      mainProductInfo: [], //关联产品
      mainProId: 0,

      checkList: [], //当前选中的国家列表
      editcountryBox: false, //编辑国家弹框
      editCusId: 0, //编辑的用户id
      lookCountryBox: false,
      lookCheckList: [], //进行插卡的国家列表
      checkListShow: [],
      userCodeType: false, //当前客户编码的类型（false：员工填写 true：公司规定）
      user237Value: "", //公司规定的值
      userEditBox: false, //员工填写弹框
      showErp: false, //展示erp
      showProductMore: false,//展示关联产品选择
      p_currpage: 1, //当前页
			p_pagesize: 10, //一页显示的条数
			p_tolRecord: 0, //总条数
			p_input: '', //关联产品搜索内容
			allProduct: false,//产品全选
			pageCount: 1,
			checkList2: [],//页面上选择的关联产品
			checkList3: [],//弹框上选择的关联产品
			pageProductList: [],//页面显示的关联产品id

			showLabel: false,
			labelId: 0,
    };
  },
  props: [
    "bigType", //左侧大类标识,0客户分类展开,1客户分类收缩,2客户分类,3客户状态
    "bigTypeValue", //bigType对应的值
    "myTitle",
    "filterNum"
  ],
  components: {
    smallLayer,
    createFollow,
    moreInfoEdit,
    commonSearch,
    writeMessage,
    purchaseSearch,
    mainProduct,
    allCountry
  },
  methods: {
    ...mapMutations({
      getCustomerClassifyAll: "getCustomerClassifyAll",
      getCustomerFromAll: "getCustomerFromAll",
      getCustomerRelationAll: "getCustomerRelationAll",
      getCustomerStatusAll: "getCustomerStatusAll",
      getIndustryType: "getIndustryType",
      getMajorProductAll: "getMajorProductAll",
      getNecessaryitemAll: "getNecessaryitemAll",
      getUserlistAll: "getUserlistAll",
      getSaleProcessAll: "getSaleProcessAll",
      fun_loadLicencefun: "fun_loadLicencefun",
      updateFollowcount : 'updateFollowcount',
      getProductlistAll2: 'getProductlistAll2',
      fun_userlablenumlist: 'fun_userlablenumlist',
    }),
    //关联产品搜索
    searchProClick() {
    	if(this.p_input == '' || this.p_input.length == 0){
    		this.$message({
          showClose: true,
         	duration: 2000,
          message: "请输入产品名称或者产品编号搜索!",
          type: "warning"
        });
    		return;
    	}
    	this.p_currpage = 1;
			this.p_pagesize = 10;
    	this.getProductlistAll2({
				currpage: this.p_currpage,
				pagesize: this.p_pagesize,
				conditionValue: this.p_input
			});
			if (this.productlistall2 != "" && this.productlistall2 != null)
    	{
      	this.mainProductInfo = this.productlistall2.productclassifylist;
    		this.p_tolRecord = this.productlistall2.pageBean.totalRecord;
    		this.pageCount = this.productlistall2.pageBean.totalPage;
    	} else {
      	this.mainProductInfo = [];
      	this.p_tolRecord = 0;
      	this.pageCount = 1;
    	}
		},
    //重置搜索关联产品
		reset_proInput() {
			if(this.p_input == ""){
				return;
			}
			this.p_currpage = 1;
			this.p_pagesize = 10;
			this.p_input = "";
			this.getProductlistAll2({
				currpage: this.p_currpage,
				pagesize: this.p_pagesize,
				conditionValue: this.p_input
			});
			if (this.productlistall2 != "" && this.productlistall2 != null)
    	{
      	this.mainProductInfo = this.productlistall2.productclassifylist;
    		this.p_tolRecord = this.productlistall2.pageBean.totalRecord;
    		this.pageCount = this.productlistall2.pageBean.totalPage;
    	} else {
      	this.mainProductInfo = [];
      	this.p_tolRecord = 0;
      	this.pageCount = 1;
    	}
		},
		//关联产品全选
		getAllProductInfo(){
			this.checkList3 = [];
			if (this.allProduct) {
				let self = this
				$.each(this.mainProductInfo, function (i, row) {
					self.checkList3.push(row.id);
				})
			}
		},
    //分页查询关联产品
		p_changeCurrentPage(page) {
			this.p_currpage = page;
			this.p_pagesize = 10;
    	this.getProductlistAll2({
				currpage: this.p_currpage,
				pagesize: this.p_pagesize,
				conditionValue: this.p_input
			});
			if (this.productlistall2 != "" && this.productlistall2 != null)
    	{
      	this.mainProductInfo = this.productlistall2.productclassifylist;
    		this.p_tolRecord = this.productlistall2.pageBean.totalRecord;
    		this.pageCount = this.productlistall2.pageBean.totalPage;
    	} else {
      	this.mainProductInfo = [];
      	this.p_tolRecord = 0;
      	this.pageCount = 1;
    	}
		},
		//条数查询关联产品
		p_changeSizePage(size) {
			this.p_pagesize = size;
    	this.getProductlistAll2({
				currpage: this.p_currpage,
				pagesize: this.p_pagesize,
				conditionValue: this.p_input
			});
			if (this.productlistall2 != "" && this.productlistall2 != null)
    	{
      	this.mainProductInfo = this.productlistall2.productclassifylist;
    		this.p_tolRecord = this.productlistall2.pageBean.totalRecord;
    		this.pageCount = this.productlistall2.pageBean.totalPage;
    	} else {
      	this.mainProductInfo = [];
      	this.p_tolRecord = 0;
      	this.pageCount = 1;
    	}
		},
		//清除当前选中的关联产品的选项
    cancelProductCheck() {
      this.cuscondition = 51;
      this.cusconditionvalue = "";
      this.loadCustomerList();
      this.checkList2 = [];
      this.checkList3 = [];
    },
		//确认选择关联产品
		submitProInfo() {
			var ids = "";
			for(var i = 0;i < this.checkList3.length;i++){
				ids += this.checkList3[i]+",";
			}
			if(ids != ""){
				ids = ids.substring(0,ids.length-1);
			}
			this.cuscondition = 51;
      this.cusconditionvalue = ids;
      this.loadCustomerList();
			this.showProductMore = false;
		},
		//关闭选择关联产品
		closeProductDialog(){
			this.checkList3 = [];
			this.showProductMore = false;
		},
		//页面上的关联产品选择事件
		getProductValue(){
			var ids = "";
			for(var i = 0;i < this.checkList2.length;i++){
				ids += this.checkList2[i]+",";
			}
			if(ids != ""){
				ids = ids.substring(0,ids.length-1);
			}
			this.cuscondition = 51;
      this.cusconditionvalue = ids;
      this.loadCustomerList();
		},
    //页数改变执行
    siezChange(size) {
      this.cus_pagesize = size;
      this.loadCustomerList();
    },
    //本周录入
    listFilter1() {
      this.itemNum = -1;
      $(".opr-btn.condition-btn").removeClass("selectFilter");
      this.$emit("listFilter1");
      this.cus_currpage = 1;
      this.cuscondition = 9;
      this.cusconditionvalue = 0;
      this.loadCustomerList();
    },
    //本周联系
    listFilter2() {
      this.itemNum = -1;
      $(".opr-btn.condition-btn").removeClass("selectFilter");
      this.$emit("listFilter2");
      this.cus_currpage = 1;
      this.cuscondition = 15;
      this.cusconditionvalue = 0;
      this.loadCustomerList();
    },
    //本月联系
    listFilter3() {
      this.itemNum = -1;
      $(".opr-btn.condition-btn").removeClass("selectFilter");
      this.$emit("listFilter3");
      this.cus_currpage = 1;
      this.cuscondition = 16;
      this.cusconditionvalue = 0;
      this.loadCustomerList();
    },
    //7天未跟进
    listFilter53() {
      this.itemNum = -1;
      $(".opr-btn.condition-btn").removeClass("selectFilter");
      this.$emit("listFilter53");
      this.cus_currpage = 1;
      this.cuscondition = 53;
      this.cusconditionvalue = 0;
      this.loadCustomerList();
    },
    //30天未联系
    listFilter4() {
      this.itemNum = -1;
      $(".opr-btn.condition-btn").removeClass("selectFilter");
      this.$emit("listFilter4");
      this.cus_currpage = 1;
      this.cuscondition = 22;
      this.cusconditionvalue = 0;
      this.loadCustomerList();
    },
    //客户例表排序
    customerSort(sortTag) {
      this.cus_currpage = 1;
      this.sort_tag = sortTag;
      this.loadCustomerList();
    },
    //右侧子查询
    rightLoadCustomerList(e, con, convalue) {
      if (e != 0) {
        $(".opr-btn").removeClass("selectFilter"); //移除样式
        $(e.target).addClass("selectFilter"); //添加选中的样式
      }
      this.checkList = [];
      this.checkListShow = [];
      this.cus_currpage = 1;
      this.cuscondition = con;
      this.cusconditionvalue = convalue;
      this.loadCustomerList();
    },
    //顶部简单查询
    queryCustomerSimple(info) {
      this.itemNum = -1;
      $(".opr-btn").removeClass("selectFilter");
      this.$emit("listFilter5");
      this.cus_currpage = 1;
      this.cus_pagesize = 10;
      this.rightLoadCustomerList(0, 33, info);
    },
    //顶部高级查询
    queryCustomerHigh(info) {
      this.itemNum = -1;
      $(".opr-btn").removeClass("selectFilter");
      this.$emit("listFilter5");
      this.cus_currpage = 1;
      this.rightLoadCustomerList(0, 32, info);
    },
    //采购偏好更多
    purchaseMore(e, value) {
      $(".opr-btn").removeClass("selectFilter"); //移除样式
      //$(e.target).addClass("selectFilter"); //添加选中的样式
      if (value == 1) {
        //关联产品
        this.checkList3 = [];
        this.showProductMore = true;
      } else {
        //采购偏好
        this.showPurchase = true;
      }
    },
    //关联产品查询确认
    submitProBtn(mainProId) {
      this.mainShow = false;
      this.mainProId = mainProId;
      this.rightLoadCustomerList(0, 51, mainProId);
    },
    //根据选中的采购偏好进行查询
    purchaseByValue(purchase_value) {
      this.showPurchase = false;
      this.purchaseValue = purchase_value;
      this.rightLoadCustomerList(0, 52, purchase_value);
    },
    //加载客户例表
    loadCustomerList() {
      let _this = this;
      _this.selectArr = [];
      let newBigType = _this.bigType;
      if (newBigType == 1) {
        newBigType = 0;
      }
      let newCusCondition = _this.cuscondition;
      if (newCusCondition == 1) {
        newCusCondition = 0;
      }
      if (newCusCondition == 48) {
        //7天内未联系客户加入公海库
        _this.cusconditionvalue = 100;
        if (
          _this.companyparamlist.param176 != undefined &&
          _this.companyparamlist.param176 != "" &&
          _this.companyparamlist.param176 != "0"
        ) {
          _this.cusconditionvalue = _this.companyparamlist.param176;
        }
      } else if (newCusCondition == 49) {
        //天内未成交客户加入公海库
        if (_this.companyparamlist.param183 == 0) {
          _this.$message({
            showClose: true,
            duration: 2000,
            message: "企业未启用未成交客户加入公海库的功能!",
            type: "warning"
          });
          return;
        }
        _this.cusconditionvalue = 90;
        if (
          _this.companyparamlist.param184 != undefined &&
          _this.companyparamlist.param184 != "" &&
          _this.companyparamlist.param184 != "0"
        ) {
          _this.cusconditionvalue = _this.companyparamlist.param184;
        }
      } else if (newCusCondition == 50) {
        //7天内成交客户未联系加入公海库
        if (_this.companyparamlist.param185 == 0) {
          _this.$message({
            showClose: true,
            duration: 2000,
            message: "企业未启用成交客户未联系加入公海库的功能!",
            type: "warning"
          });
          return;
        }
        _this.cusconditionvalue = 90;
        if (
          _this.companyparamlist.param186 != undefined &&
          _this.companyparamlist.param186 != "" &&
          _this.companyparamlist.param186 != "0"
        ) {
          _this.cusconditionvalue = _this.companyparamlist.param186;
        }
      }
      $.ajax({
        type: "post",
        url: backUrl + "cus_findValueCustomer.action",
        async: true,
        cache: false,
        dataType: "json",
        beforeSend: function() {
          _this.autographLoading = true;
        },
        complete: function() {
          _this.autographLoading = false;
        },
        data: {
          token: token,
          pagesize: _this.cus_pagesize,
          currpage: _this.cus_currpage,
          condition: newCusCondition,
          value: _this.cusconditionvalue,
          select_type: newBigType,
          select_value: _this.bigTypeValue,
          sort_tag: _this.sort_tag
        },
        success: function(data) {
          _this.cus_tolpage = data.pageBean.totalPage; //总页数
          _this.cus_tolRecord = data.pageBean.totalRecord; //总条数
          _this.customerList = [];
          $.each(data.crmcustomerlist, function(index, row) {
            let country = "无"; //当前获取的国家名
            let countryClass = ""; //当前所在国家有无获取到
            var imgurl = backUrl + "img/male_user.png";
            let userCode = "";
            let userEdit = false;
            //img/common/user.png"
            if (
              row.img_url !== undefined &&
              row.img_url !== null &&
              row.img_url != "null" &&
              row.img_url != "null" &&
              row.img_url !== ""
            ) {
              if (
                row.img_url.indexOf("http://") != -1 ||
                row.img_url.indexOf("https://") != -1
              ) {
                imgurl = row.img_url;
              } else {
                imgurl = backUrl + row.img_url;
              }
            }
            var enrol_date = row.enrol_time.split(" ")[0];
            var enrol_time = row.enrol_time.split(" ")[1].replace(".0", "");
            var contact_date = "暂无活跃";
            var contact_time = "";
            if (row.last_contact_date != null && row.last_contact_date != "") {
              contact_date = row.last_contact_date.split(" ")[0];
              contact_time = row.last_contact_date.split(" ")[1];
            }
            var process_name = "无";
            if (_this.saleprocessall == "") {
              _this.getSaleProcessAll();
            }
            $.each(_this.saleprocessall, function(i, j) {
              if (j.id == row.sale_process) {
                process_name = j.name;
                return false;
              }
            });
//          var share_name = "";
//          if (row.current_status == 2) {
//            share_name = "(已共享)";
//          }
//          var is_lock2 = "";
//          if (row.is_lock2 == 1) {
//            is_lock2 = "(被锁定)";
//          }
            var customer_name = row.customer_name;
            if (customer_name.length > 50) {
              //customer_name = customer_name.substring(0, 47) + share_name + is_lock2 + "...";
              customer_name = customer_name.substring(0, 47);
            }
//          else {
//            customer_name = customer_name + share_name + is_lock2;
//          }
            //判断当前有无获取到国家
            for (
              let i = 0, iLen = _this.$store.state.countryArr.length;
              i < iLen;
              i++
            ) {
              if (_this.$store.state.countryArr[i].name == row.country) {
                country = row.country;
                countryClass = _this.$store.state.countryArr[i].class;
                break;
              }
            }
            //获取到客户编码
            if (
              row.organization_code != "" &&
              row.organization_code != null &&
              row.organization_code != undefined
            ) {
              userCode = row.organization_code;
              userEdit = false;
            } else {
              userCode = "无";
              userEdit = true;
            }
            //获取自定义标签
            var labelName = "";
            var labelColor = "";
            if(row.black_flag > 0){
            	for(var k = 0;k < _this.pmail_userlablenum.length;k++){
            		if(row.black_flag == _this.pmail_userlablenum[k].mail_tips_id){
            			labelName = _this.pmail_userlablenum[k].mail_tips;
            			labelColor = _this.pmail_userlablenum[k].backgrond;
            			break;
            		}
            	}
            }
            _this.customerList.push({
              user_id: row.user_id,
              customer_id: row.customer_id,
              process_name: process_name,
              process_name2: process_name.substring(0, 1),
              img_url: imgurl,
              customer_name: customer_name,
              link_email: row.email,
              important_star: row.important_star,
              enrol_date: enrol_date,
              enrol_time: enrol_time,
              contact_date: contact_date,
              contact_time: contact_time,
              partnership: row.partnership,
              calling: row.calling,
              classify_id: row.classify_id,
              customer_status: row.customer_status,
              source_from_id: row.source_from_id,
              prductid: row.prductid,
              is_lock2: row.is_lock2,
              country: country,
              countryClass: countryClass,
              userCode: userCode,
              userEdit: userEdit,
              labelName: labelName,
              labelColor: labelColor
            });
          });
          //处理顶部当前位置信息
          var first = "";
          var second = "";
          var third = "";
          _this.showErp = false;
          if (_this.bigType == 2) {
            //客户分类
            first = "客户分类";
            if (_this.bigTypeValue == 0) {
              second = "未分类";
            }
            $.each(_this.customerclassifyall, function(i, row) {
              if (_this.bigTypeValue == row.id) {
                second = row.name;
                return false;
              }
						});
						//判断是否开通erp
           _this.showErp=_this.commonJs.hasErp(_this.bigTypeValue);
          } else if (_this.bigType == 3) {
            //客户状态
            first = "客户状态";
            $.each(_this.customerstatusall, function(i, row) {
              if (_this.bigTypeValue == row.id) {
                second = row.name;
                return false;
              }
            });
          } else {
            //全部客户
            first = "全部客户";
          }
          if (_this.bigType != _this.cuscondition) {
            //包括右边子查询
            if (_this.cuscondition == 9) {
              //录入时间-本周录入
              third = "录入时间(本周录入)";
            } else if (_this.cuscondition == 46) {
              //录入时间-昨天
              third = "录入时间(昨天录入)";
            } else if (_this.cuscondition == 45) {
              //录入时间-最近7天
              third = "录入时间(最近7天录入)";
            } else if (_this.cuscondition == 47) {
              //录入时间-最近30天
              third = "录入时间(最近30天录入)";
            } else if (_this.cuscondition == 13) {
              //录入时间-自定义
              third =
                "录入时间(" +
                _this.dateValue +
                "至" +
                _this.dateValue2 +
                "录入)";
            } else if (_this.cuscondition == 15) {
              //联系时间-本周联系
              third = "联系时间(本周联系)";
            } else if (_this.cuscondition == 16) {
              //联系时间-本月联系
              third = "联系时间(本月联系)";
            } else if (_this.cuscondition == 22) {
              //联系时间-30天未联系
              third = "联系时间(30天未跟进)";
            } else if (_this.cuscondition == 14) {
              //联系时间-今天联系
              third = "联系时间(今天联系)";
            } else if (_this.cuscondition == 15) {
              //联系时间-本周联系
              third = "联系时间(本周联系)";
            } else if (_this.cuscondition == 16) {
              //联系时间-本月联系
              third = "联系时间(本月联系)";
            } else if (_this.cuscondition == 17) {
              //联系时间-上月联系
              third = "联系时间(上月联系)";
            } else if (_this.cuscondition == 18) {
              //联系时间-最近三个月联系
              third = "联系时间(最近三个月联系)";
            } else if (_this.cuscondition == 19) {
              //录入时间-自定义
              third =
                "联系时间(" +
                _this.dateValue3 +
                "至" +
                _this.dateValue4 +
                "联系)";
            } else if (_this.cuscondition == 25) {
              //客户关系
              if (_this.cusconditionvalue == 0) {
                third = "客户关系(无)";
              } else {
                var name = "无";
                $.each(_this.customerrelationall, function(i, row) {
                  if (_this.cusconditionvalue == row.id) {
                    name = row.name;
                    return false;
                  }
                });
                third = "客户关系(" + name + ")";
              }
            } else if (_this.cuscondition == 26) {
              //客户来源
              if (_this.cusconditionvalue == 0) {
                third = "客户来源(无)";
              } else {
                var name = "无";
                $.each(_this.customerfromall, function(i, row) {
                  if (_this.cusconditionvalue == row.id) {
                    name = row.name;
                    return false;
                  }
                });
                third = "客户来源(" + name + ")";
              }
            } else if (_this.cuscondition == 37) {
              //客户星级
              if (_this.cusconditionvalue == 1) {
                third = "客户星级(一星)";
              } else if (_this.cusconditionvalue == 2) {
                third = "客户星级(二星)";
              } else if (_this.cusconditionvalue == 3) {
                third = "客户星级(三星)";
              } else if (_this.cusconditionvalue == 4) {
                third = "客户星级(四星)";
              } else if (_this.cusconditionvalue == 5) {
                third = "客户星级(五星)";
              } else {
                third = "客户星级(无)";
              }
            } else if (_this.cuscondition == 48) {
              //7天内未联系客户入公海库
              third = "我的公海(7天内未联系客户入公海库)";
            } else if (_this.cuscondition == 49) {
              //7天内未成交客户入公海库
              third = "我的公海(7天内未成交客户入公海库)";
            } else if (_this.cuscondition == 50) {
              //7天内成交未联系客户入公海库
              third = "我的公海(7天内成交未联系客户入公海库)";
            } else if (_this.cuscondition == 40) {
              //成交记录
              if (_this.cusconditionvalue == 1) {
                third = "成交记录(5单内)";
              } else if (_this.cusconditionvalue == 2) {
                third = "成交记录(10单内)";
              } else if (_this.cusconditionvalue == 0) {
                third = "成交记录(未成交)";
              } else {
                third =
                  "成交记录(" + _this.numValue + "至" + _this.numValue2 + ")";
              }
            } else if (_this.cuscondition == 41) {
              //成交金额
              third =
                "成交记录(" + _this.numValue3 + "至" + _this.numValue4 + ")";
            } else if (_this.cuscondition == 42) {
              //入库方式
              if (_this.cusconditionvalue == 1) {
                third = "入库方式(手工创建)";
              } else if (_this.cusconditionvalue == 2) {
                third = "入库方式(普通导入)";
              } else if (_this.cusconditionvalue == 3) {
                third = "入库方式(系统导入)";
              } else if (_this.cusconditionvalue == 4) {
                third = "入库方式(联系人转为)";
              } else if (_this.cusconditionvalue == 5) {
                third = "入库方式(别人转移)";
              } else if (_this.cusconditionvalue == 6) {
                third = "入库方式(回收站转移)";
              } else if (_this.cusconditionvalue == 7) {
                third = "入库方式(回收站恢复)";
              } else if (_this.cusconditionvalue == 8) {
                third = "入库方式(公海库领取)";
              } else if (_this.cusconditionvalue == 9) {
                third = "入库方式(收邮件保存)";
              }
            } else if (_this.cuscondition == 51) {
              //关联产品
              if (_this.cusconditionvalue == "") {
                third = "";
              } else {
                var name = "";
                var product_arr = _this.cusconditionvalue.split(',');
                for(var i = 0;i < product_arr.length;i++){
                	for(var j = 0;j < _this.mainProductInfo.length;j++){
                		if(parseInt(product_arr[i]) == _this.mainProductInfo[j].id){
                			name += _this.mainProductInfo[j].name + ",";
                			break;
                		}
                	}
                }
                if(name != ""){
                	name = name.substring(0,name.length-1);
                	third = "关联产品(" + name + ")";
                }else{
                	third = "";
                }
              }
            } else if (_this.cuscondition == 34) {
              //销售进程
              if (_this.cusconditionvalue == 0) {
                third = "销售进程(无)";
              } else if (_this.cusconditionvalue == -1) {
                third = "销售进程(所有)";
              } else {
                var name = "无";
                $.each(_this.saleprocessall, function(i, row) {
                  if (_this.cusconditionvalue == row.id) {
                    name = row.name;
                    return false;
                  }
                });
                third = "销售进程(" + name + ")";
              }
            }else if (_this.cuscondition == 57) { //自定义标签
              if (_this.cusconditionvalue == 0) {
                third = "自定义标签(无)";
              }else {
                var name = "无";
                $.each(_this.pmail_userlablenum, function(i, row) {
                  if (_this.cusconditionvalue == row.mail_tips_id) {
                    name = row.mail_tips;
                    return false;
                  }
                });
                third = "自定义标签(" + name + ")";
              }
            }else if (_this.cuscondition == 32) {
              third = "高级搜索";
            } else if (_this.cuscondition == 33) {
              third = "快捷搜索";
            } else if (_this.cuscondition == 39) {
              third = "已入库联系人定位";
            } else if (_this.cuscondition == 52) {
              var name = "更多";
              $.each(_this.purchaseLists, function(i, row) {
                if (_this.cusconditionvalue == row.name) {
                  name = row.name;
                  return false;
                }
              });
              third = "采购偏好(" + name + ")";
            } else if (_this.cuscondition == 53) {
              third = "联系时间(7天未跟进)";
            } else if (_this.cuscondition == 54) {
              third = "客户未跟进工作台跳转";
            } else if (_this.cuscondition == 56) {
              if(_this.cusconditionvalue != ''){
              	third = "所在国家";
              }else{
              	third = "";
              }
            }
            //所在国家的显示
            if (_this.cuscondition != 56) {
              _this.checkListShow = [];
              _this.checkList = [];
            }
            //关联产品的显示
            if (_this.cuscondition != 51) {
              _this.checkList2 = [];
              _this.checkList3 = [];
            }
            if (second != "") {
            	if(third != ""){
            		_this.myTitle.name = first + "->" + second + "->" + third;
            	}else{
            		_this.myTitle.name = first + "->" + second;
            	}
            } else {
              if (third != "") {
                _this.myTitle.name = first + "->" + third;
              } else {
                _this.myTitle.name = first;
              }
            }
          } else {
            //不包括右边子查询
            if (second != "") {
              _this.myTitle.name = first + "->" + second;
            } else {
              _this.myTitle.name = first;
            }
            _this.checkListShow = [];
            _this.checkList = [];
          }
        }
      });
    },
    //获取客户的第一条动态
    getFirstDynamic(customer_id) {
      var lef = this;
      $.ajax({
        type: "POST",
        async: false,
        cache: false,
        url: backUrl + "dynamic_findFirstDynamicByCusid.action",
        data: {
          token: token,
          customer_id: customer_id
        },
        success: function(msg) {
          if (msg != null && msg != "") {
            //获取动态内容的纯文本
            var dynamicText = msg.replace(/<[^>]*>|/g, "");
            lef.cusDynamic = "最新动态:" + dynamicText;
          } else {
            lef.cusDynamic = "最新动态:暂无动态";
          }
        }
      });
    },
    //新建跟进
    newFollow: function() {
      if (this.selectArr.length == 0) {
        this.$message({
          showClose: true,
          message: "请选择客户列表！",
          type: "warning",
          duration: 2000
        });
        return;
      }
      var lef = this;
      var cud_ids = ""; //客户id
      $.each(lef.selectArr, function(i, row) {
        cud_ids += row + ",";
      });
      cud_ids = cud_ids.substring(0, cud_ids.length - 1);
      lef.customerids = cud_ids;
      lef.userId = this.userinfo.user_id;
      lef.showF = true;
    },
    //标记自定义标签
    updateLabel(){
    	if (this.selectArr.length == 0) {
        this.$message({
          showClose: true,
          message: "请选择客户列表！",
          type: "warning",
          duration: 2000
        });
        return;
      }
    	this.showLabel = true;
    },
    //确定提交标记
    labelConfirm(){
    	if(this.labelId == 0){
    		this.$message({
          showClose: true,
          message: "请选择自定义标签！",
          type: "warning",
          duration: 2000
        });
        return;
    	}
    	var cud_ids = ""; //客户id
      $.each(this.selectArr, function(i, row) {
        cud_ids += row + ",";
      });
      cud_ids = cud_ids.substring(0, cud_ids.length - 1);
      var lef = this;
      $.ajax({
        type: "POST",
        cache: false,
        url: backUrl + "cus_updateCustomerTips.action",
        data: {
          token: token,
          customerid: cud_ids,
          tip_id: lef.labelId
        },
        beforeSend: function() {
          lef.$store.state.popupLoading = true;
        },
        complete: function() {
          lef.$store.state.popupLoading = false;
        },
        success: function(msg) {
          if (msg == 1) {
            lef.$message({
              showClose: true,
              message: "操作成功！",
              type: "success",
              duration: 2000
            });
           	lef.showLabel = false;
            //重新加载客户列表
            lef.loadCustomerList();
          } else {
            lef.$message({
              showClose: true,
              message: "操作失败！",
              type: "warning",
              duration: 2000
            });
          }
        },
        error: function() {
          lef.$message({
            showClose: true,
            message: "操作失败！",
            type: "warning",
            duration: 2000
          });
        }
      });
    },
    //更多操作
    handleCommand: function(command) {
      if (this.selectArr.length == 0 && command != "e") {
        this.$message({
          showClose: true,
          message: "请选择客户列表！",
          type: "warning",
          duration: 2000
        });
        return;
      }

      if (command == "a") {
        //删除客户

        var lef = this;

        var lock_customer = "";
        $.each(lef.customerList, function(s, row) {
          $.each(lef.selectArr, function(i, id) {
            if (id == row.customer_id) {
              if (row.is_lock2 == 1) {
                lock_customer += "," + row.customer_name;
              }
              return;
            }
          });
        });

        if (lock_customer.length > 0) {
          lef.$message({
            showClose: true,
            message:
              "[ " +
              lock_customer.substring(1) +
              " ]已经被锁定，请先取消锁定再删除！",
            type: "warning",
            duration: 3000
          });
          return;
        }

        this.$confirm("确定删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          closeOnClickModal: false,
          cancelButtonClass: "MBcancelBtn"
        })
          .then(() => {
            var cud_ids = ""; //客户id
            $.each(lef.selectArr, function(i, row) {
              cud_ids += row + ",";
            });
            cud_ids = cud_ids.substring(0, cud_ids.length - 1);

            $.ajax({
              type: "POST",
              url: backUrl + "cus_checkCusIsShare.action",
              data: {
                token: token,
                customerid: cud_ids
              },
              dataType: "json",
              cache: false,
              success: function(msg) {
                if (msg.result == 1) {
                  //有共享客户
                  var share_customer = "";

                  $.each(msg.crmcustomerlist, function(s, row) {
                    share_customer = share_customer + row.customer_name + ",";
                  });

                  share_customer = share_customer.substring(
                    0,
                    share_customer.length - 1
                  );

                  lef.$message({
                    showClose: true,
                    message:
                      "[ " +
                      share_customer +
                      " ]已经共享给其它用户，请先取消共享再删除！",
                    type: "warning",
                    duration: 3000
                  });
                } //没有共享客户，则直接删除
                else {
                  $.ajax({
                    type: "POST",
                    url: backUrl + "cus_updateCusStatus.action",
                    data: {
                      token: token,
                      customerid: cud_ids,
                      condition: -1,
                      upid: lef.userinfo.user_id
                    },
                    dataType: "json",
                    cache: false,
                    success: function(msg) {
                      if (msg == 1) {
                        lef.$message({
                          showClose: true,
                          message: "删除成功！",
                          type: "success",
                          duration: 2000
                        });
                        //重新加载客户例表
                        lef.loadCustomerList();
                        //重新加载回收站客户数量&&全部客户数量&&各分类客户数量&&各状态客户数量&&已入库联系数量
                        lef.$store.state.loadCusNumTag = 3;
                      } else {
                        lef.$message({
                          showClose: true,
                          message: "删除失败！",
                          type: "warning",
                          duration: 2000
                        });
                      }
                    },
                    error: function(e) {
                      lef.$message({
                        showClose: true,
                        message: "删除失败！",
                        type: "warning",
                        duration: 2000
                      });
                    }
                  });
                }
              },
              error: function(e) {
                lef.$message({
                  showClose: true,
                  message: "删除失败！",
                  type: "warning",
                  duration: 2000
                });
              }
            });
          })
          .catch(() => {});
      } else if (command == "b") {
        //转移给
        var lef = this;

        var lock_customer = "";
        $.each(lef.customerList, function(s, row) {
          $.each(lef.selectArr, function(i, id) {
            if (id == row.customer_id) {
              if (row.is_lock2 == 1) {
                lock_customer += "," + row.customer_name;
              }
              return;
            }
          });
        });

        if (lock_customer.length > 0) {
          lef.$message({
            showClose: true,
            message:
              "[ " +
              lock_customer.substring(1) +
              " ]已经被锁定，请先取消锁定再转移！",
            type: "warning",
            duration: 3000
          });

          return;
        }

        lef.showC = true;
      } else if (command == "c") {
        //共享给
        this.showShare = true;
      } else if (command == "d") {
        //加入公海
        this.$confirm("确定加入公海?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          closeOnClickModal: false,
          cancelButtonClass: "MBcancelBtn"
        })
          .then(() => {
            var lef = this;
            var cud_ids = ""; //客户id
            $.each(lef.selectArr, function(i, row) {
              cud_ids += row + ",";
            });
            cud_ids = cud_ids.substring(0, cud_ids.length - 1);
            $.ajax({
              type: "POST",
              url: backUrl + "cus_checkCusIsShare.action",
              data: {
                token: token,
                customerid: cud_ids
              },
              dataType: "json",
              cache: false,
              success: function(msg) {
                if (msg.result == 1) {
                  //有共享客户
                  var share_customer = "";

                  $.each(msg.crmcustomerlist, function(s, row) {
                    share_customer = share_customer + row.customer_name + ",";
                  });

                  share_customer = share_customer.substring(
                    0,
                    share_customer.length - 1
                  );

                  lef.$message({
                    showClose: true,
                    message:
                      "[ " +
                      share_customer +
                      " ]已经共享给其它用户，请先取消共享再加入公海！",
                    type: "warning",
                    duration: 3000
                  });
                } //没有共享客户，则加入公海
                else {
                  var lock_customer = "";
                  $.each(lef.customerList, function(s, row) {
                    $.each(lef.selectArr, function(i, id) {
                      if (id == row.customer_id) {
                        if (row.is_lock2 == 1) {
                          lock_customer += "," + row.customer_name;
                        }
                        return;
                      }
                    });
                  });

                  if (lock_customer.length > 0) {
                    lef.$message({
                      showClose: true,
                      message:
                        "[ " +
                        lock_customer.substring(1) +
                        " ]已经被锁定，请先取消锁定再加入公海！",
                      type: "warning",
                      duration: 3000
                    });

                    return;
                  }

                  $.ajax({
                    type: "POST",
                    url: backUrl + "cusCP_addPublic.action",
                    data: {
                      token: token,
                      customerids: cud_ids
                    },
                    dataType: "json",
                    cache: false,
                    success: function(msg) {
                      if (msg == 1) {
                        lef.$message({
                          showClose: true,
                          message: "加入公海库成功！",
                          type: "success",
                          duration: 2000
                        });
                        //重新加载客户例表
                        lef.loadCustomerList();
                        //重新加载公海客户数量&&全部客户数量&&各分类客户数量&&各状态客户数量&&已入库联系数量
                        lef.$store.state.loadCusNumTag = 1;
                      } else {
                        lef.$message({
                          showClose: true,
                          message: "加入公海库失败！",
                          type: "warning",
                          duration: 2000
                        });
                      }
                    },
                    error: function(e) {
                      lef.$message({
                        showClose: true,
                        message: "加入公海库失败！",
                        type: "warning",
                        duration: 2000
                      });
                    }
                  });
                }
              },
              error: function(e) {
                lef.$message({
                  showClose: true,
                  message: "加入公海库失败！",
                  type: "warning",
                  duration: 2000
                });
              }
            });
          })
          .catch(() => {});
      } else if (command == "e") {
        //导出客户
        if (this.selectArr.length == 0) {
          //没选择客户例表，则导出左侧选择的全部
          var lef = this;
          var excel_name = "全部客户";
          if (lef.bigType == 2) {
            //客户分类
            $.each(lef.customerclassifyall, function(i, row) {
              if (lef.bigTypeValue == row.id) {
                excel_name = "客户分类->" + row.name;
                return false;
              }
            });
          } else if (lef.bigType == 3) {
            //客户状态
            $.each(lef.customerstatusall, function(i, row) {
              if (lef.bigTypeValue == row.id) {
                excel_name = "客户状态->" + row.name;
                return false;
              }
            });
          }
          //						lef.$confirm('是否导出[ ' + excel_name + ' ]的所有客户?', '提示', {
          lef
            .$confirm("您确定要导出所有吗？", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              closeOnClickModal: false,
              cancelButtonClass: "MBcancelBtn"
            })
            .then(() => {
              var urlHref =
                backUrl +
                "export_exportCustomerByType.action?token=" +
                token +
                "&export_type=2&userid=" +
                lef.userinfo.user_id +
                "&excel_name=" +
                excel_name +
                "&codition=" +
                lef.bigType +
                "&codition_value=" +
                lef.bigTypeValue;
              //$("#downloadid").attr("src", urlHref);
              window.open(urlHref);
            })
            .catch(() => {});
        } else {
          //导出选择客户例表
          this.$confirm("您确定要导出已选择的客户信息吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            cancelButtonClass: "cancelButtonClass",
            closeOnClickModal: false,
            type: "warning"
          })
            .then(() => {
              var cud_ids = ""; //客户id
              $.each(this.selectArr, function(i, row) {
                cud_ids += row + ",";
              });
              cud_ids = cud_ids.substring(0, cud_ids.length - 1);
              var urlHref =
                backUrl +
                "export_exportCustomerByType.action?token=" +
                token +
                "&export_type=1&customerids=" +
                cud_ids +
                "&excel_name=选中客户";
              //$("#downloadid").attr("src", urlHref);
              window.open(urlHref);
            })
            .catch(() => {});
        }
      } else if (command == "f" || command == "g") {
        //f锁定/g解锁
        var lef = this;
        var msg = "您确定要锁定选中的客户吗？";
        var is_lock = 1;
        if (command == "g") {
          msg = "您确定要解锁选中的客户吗？";
          is_lock = 0;
        }

        lef
          .$confirm(msg, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            closeOnClickModal: false,
            cancelButtonClass: "MBcancelBtn"
          })
          .then(() => {
            var cud_ids = ""; //客户id
            $.each(this.selectArr, function(i, row) {
              cud_ids += row + ",";
            });
            cud_ids = cud_ids.substring(0, cud_ids.length - 1);

            $.ajax({
              type: "post",
              url: backUrl + "cus_updateCustomerLockStatus2.action",
              data: {
                token: token,
                customerid: cud_ids,
                is_lock: is_lock
              },
              success: function(msg) {
                if (msg == 1) {
                  lef.$message({
                    showClose: true,
                    message: "操作成功！",
                    type: "success",
                    duration: 2000
                  });
                  //重新加载客户例表
                  lef.loadCustomerList();
                } else if (msg == 2) {
                  lef.$message({
                    showClose: true,
                    message: "已超出最大锁定客户数！",
                    type: "error",
                    duration: 2000
                  });
                } else if (msg < 0) {
                  lef.$message({
                    showClose: true,
                    message: "最多还能锁定" + -(msg + 1) + "个客户！",
                    type: "error",
                    duration: 2000
                  });
                } else {
                  lef.$message({
                    showClose: true,
                    message: "操作失败！",
                    type: "error",
                    duration: 2000
                  });
                }
              },
              error: function() {
                lef.$message({
                  showClose: true,
                  message: "操作异常",
                  type: "error",
                  duration: 2000
                });
              }
            });
          })
          .catch(() => {});
      }
    },
    //提交转移
    cntConfirm: function(user_id, isMoveMail) {
      var cud_ids = ""; //客户id
      $.each(this.selectArr, function(i, row) {
        cud_ids += row + ",";
      });
      cud_ids = cud_ids.substring(0, cud_ids.length - 1);
      var movemail = 0;
      if (isMoveMail) {
        //表示转移
        movemail = -1;
      }
      var lef = this;
      //先判断转移给的用户的客户数量情况
      $.ajax({
        type: "POST",
        //					"async": false,
        cache: false,
        url: backUrl + "cus_checkCustomerCounts.action",
        data: {
          token: token,
          customerid: cud_ids,
          upid: user_id
        },
        beforeSend: function() {
          lef.$store.state.popupLoading = true;
        },
        complete: function() {
          lef.$store.state.popupLoading = false;
        },
        success: function(msg) {
          if (msg[0] == 1) {
            // 先添加客户生命周期信息
            $.ajax({
              type: "POST",
              url: backUrl + "xcus_addLifeCyclesByCusIds.action",
              async: true,
              cache: false,
              data: {
                token: token,
                customer_ids: cud_ids, //客户id
                owner_type: 2, //类型
                target_id: user_id //目标客户id
              },
              dataType: "json",
              cache: false
            });

            //调用ajax
            $.ajax({
              type: "POST",
              async: false,
              cache: false,
              url: backUrl + "cus_moveCustomerInfo.action",
              data: {
                token: token,
                customerid: cud_ids,
                upid: user_id,
                source_userid: lef.userinfo.user_id,
                up_name: lef.userinfo.user_name,
                movemail: movemail
              },
              success: function(msg) {
                if (msg == 1) {
                  // 统计跟进记录
                  lef.updateFollowcount(cud_ids);
                  lef.$message({
                    showClose: true,
                    message: "转移成功！",
                    type: "success",
                    duration: 2000
                  });
                  lef.loadCustomerList();
                  //刷新全部客户数量&&各分类客户数量&&各状态客户数量&&已入库联系数量
                  lef.$store.state.loadCusNumTag = 0;
                } else {
                  lef.$message({
                    showClose: true,
                    message: "转移失败！",
                    type: "warning",
                    duration: 2000
                  });
                }
              },
              error: function() {
                lef.$message({
                  showClose: true,
                  message: "转移失败！",
                  type: "warning",
                  duration: 2000
                });
              }
            });
          } else if (msg[0] == -1) {
            //总数量超标
            lef.$message({
              showClose: true,
              message: msg[1],
              type: "warning",
              duration: 2000
            });
          } else if (msg[0] == -2) {
            //日数量超标
            lef.$message({
              showClose: true,
              message: msg[1],
              type: "warning",
              duration: 2000
            });
          } else {
            lef.$message({
              showClose: true,
              message: "转移失败！",
              type: "warning",
              duration: 2000
            });
          }
        },
        error: function() {
          lef.$message({
            showClose: true,
            message: "转移失败！",
            type: "warning",
            duration: 2000
          });
        }
      });
    },
    //提交共享
    cntConfirmShare: function(userids) {
      var cud_ids = ""; //客户id
      $.each(this.selectArr, function(i, row) {
        cud_ids += row + ",";
      });
      cud_ids = cud_ids.substring(0, cud_ids.length - 1);
      var lef = this;
      $.ajax({
        type: "POST",
        //					"async": false,
        cache: false,
        url: backUrl + "cusCC_addShareList2.action",
        data: {
          token: token,
          customerid: cud_ids,
          shareuserid: userids
        },
        beforeSend: function() {
          lef.$store.state.popupLoading = true;
        },
        complete: function() {
          lef.$store.state.popupLoading = false;
        },
        success: function(msg) {
          if (msg == 1) {
            lef.$message({
              showClose: true,
              message: "共享成功！",
              type: "success",
              duration: 2000
            });
            //重新加载客户列表
            lef.loadCustomerList();
            //重新加载我的共享客户数量
            lef.$store.state.loadCusNumTag = 4;
          } else {
            lef.$message({
              showClose: true,
              message: "共享失败！",
              type: "warning",
              duration: 2000
            });
          }
        },
        error: function() {
          lef.$message({
            showClose: true,
            message: "共享失败！",
            type: "warning",
            duration: 2000
          });
        }
      });
    },
    //提交修改分类
    cntConfirm2: function(parvalue) {
      var cud_ids = ""; //客户id
      let nowUserId = JSON.parse(JSON.stringify(this.selectArr));
      $.each(this.selectArr, function(i, row) {
        cud_ids += row + ",";
      });
      cud_ids = cud_ids.substring(0, cud_ids.length - 1);
      var lef = this;
      let splitArr = parvalue.lastIndexOf("###");
      let upVal = parvalue.substring(0, splitArr);
      let hasErp = parvalue.substring(splitArr + 3, parvalue.length);
      var classify_id = parvalue.split("###")[5];
      $.ajax({
          type: "POST",
          url: backUrl + "cus_checkCanUpdateClassify.action",
          data: {
            token: token,
            customerid: cud_ids,
            classify_id: classify_id
          },
          success: function(msg) {
            if(msg == 'ok'){
              	  $.ajax({
						        type: "POST",
						        async: false,
						        cache: false,
						        url: backUrl + "cus_movecustomer.action",
						        data: {
						          token: token,
						          customerid: cud_ids,
						          value: upVal
						        },
						        success: function(msg) {
						          if (msg == 1) {
						            lef.$message({
						              showClose: true,
						              message: "修改分类成功！",
						              type: "success",
						              duration: 2000
						            });
						            //判读是否是erp
						            if (hasErp == 1) {
						              lef.$confirm("需要同步到ERP系统?", "提示", {
						                  confirmButtonText: "确定",
						                  cancelButtonText: "取消",
						                  cancelButtonClass: "cancelButtonClass"
						                })
						                .then(() => {
						                  lef.modifyAllERP(nowUserId);
						                  //重新加载客户列表
						                  lef.loadCustomerList();
						                })
						                .catch(() => {
						                  //重新加载客户列表
						                  lef.loadCustomerList();
						                });
						            } else {
						              //重新加载客户列表
						              lef.loadCustomerList();
						            }

						            //重新加载我的共享客户数量
						            lef.$store.state.loadCusNumTag = 7;
						          } else {
						            lef.$message({
						              showClose: true,
						              message: "修改分类失败！",
						              type: "warning",
						              duration: 2000
						            });
						          }
						        },
						        error: function() {
						          lef.$message({
						            showClose: true,
						            message: "修改分类失败！",
						            type: "warning",
						            duration: 2000
						          });
						        }
						      });
            }else if(msg == 'error'){
            		lef.$message({
              		showClose: true,
             		 	message: '修改分类失败',
              		type: "warning",
              		duration: 2000
            		});
            }else{
              	lef.$message({
              		showClose: true,
             		 	message: ''+msg,
              		type: "warning",
              		duration: 5000
            		});
            }
          }
      });
    },
    //修改分类
    modifyClient: function() {
      if (this.selectArr.length == 0) {
        this.$message({
          showClose: true,
          message: "请选择客户列表！",
          type: "warning",
          duration: 2000
        });
        return;
      }
      if (this.bigType == 2) {
        //选择的是客户分类
        this.editClassifyId = this.bigTypeValue;
        this.editStatusId = 0;
      } else if (this.bigType == 3) {
        //选择的是客户状态
        this.editStatusId = this.bigTypeValue;
        this.editClassifyId = 0;
      }
      if (this.selectArr.length != 1) {
        this.starlevel = 0;
        this.classifyid = 0;
        this.sourceid = 0;
        this.relationid = 0;
        this.statusid = 0;
        this.hangyeid = 0;
        this.productid = 0;
      }
      this.showM = true;
    },

    //选中单条信息
    selectOne(event) {
      var target = $(event.currentTarget)
        .parent()
        .parent();
      if (event.currentTarget.checked) {
        //选中
        this.starlevel = target.attr("starLevel");
        this.classifyid = target.attr("classifyId");
        this.sourceid = target.attr("sourceFromid");
        this.relationid = target.attr("partnership");
        this.statusid = target.attr("customerStatus");
        this.hangyeid = target.attr("calling");
        this.productid = target.attr("prductId");
      }
    },
    //关闭弹窗
    cntCloseUpdate() {
      this.showM = false;
      this.selectArr = [];
    },
    //添加跟进记录成功后返回
    addRecord() {
      //重新加载客户列表
      this.loadCustomerList();
    },
    //改变状态
    all_changeState() {
      this.hasEditbtn = true;
    },
    //快捷写信成功返回
    closeMessage() {
      //重新加载客户列表
      this.loadCustomerList();
      this.showMessage = false;
    },
    selectAll(event) {
      var _this = this;
      // if (!event.currentTarget.checked) {
      if (!event) {
        //全不选
        this.selectArr = [];
      } else {
        //实现全选
        _this.selectArr = [];
        _this.customerList.forEach(function(item, i) {
          _this.selectArr.push(item.customer_id);
        });
      }
    },
    showMenu: function(index) {
      if (index == 10) {
        //获取采购偏好
        var lef = this;
        $.ajax({
          type: "POST",
          async: false,
          cache: false,
          url: backUrl + "cus_findCustomerIndustries.action",
          data: {
            token: token,
            currpage: 1,
            pagesize: 10
          },
          success: function(msg) {
            lef.purchaseLists2 = msg;
            lef.purchaseLists = msg.industries.slice(0, 5);
          }
        });
      }
      this.itemNum = index;
    },
    ...mapActions(["writeLetter"]),
    //跳转到完整写信
    writeMessages(emails) {
      this.$router.push({
        path: "/mail"
      });
      this.$store.state.mail.wlPagetype = "";
      this.$store.state.mail.wlMailid = 0;
      this.writeLetter({
        name: "写信",
        addressee: emails
      });
      this.showMessage = false; //关闭快捷写信的弹窗
    },
    //快捷写信
    clickMessage(email, cus_id) {
      if (
        this.userparamlist.param39 == undefined ||
        this.userparamlist.param39 == 0
      ) {
        this.$message({
          showClose: true,
          message: "您还未开通邮件模块！",
          type: "warning",
          duration: 2000
        });
        return;
      }
      this.customer_id = cus_id;
      this.myEmail = email;
      this.showMessage = true;
    },
    //单发邮件
    sendWebmail() {
      if (
        this.userparamlist.param39 == undefined ||
        this.userparamlist.param39 == 0
      ) {
        this.$message({
          showClose: true,
          message: "您还未开通邮件模块！",
          type: "warning",
          duration: 2000
        });
        return;
      }
      if (this.selectArr.length > 0) {
        var cud_ids = ""; //客户id
        $.each(this.selectArr, function(i, row) {
          cud_ids += row + ",";
        });
        cud_ids = cud_ids.substring(0, cud_ids.length - 1);
        var lef = this;
        $.ajax({
          type: "POST",
          url: backUrl + "link_findLinkAddress.action",
          dataType: "json",
          cache: false,
          async: false,
          data: {
            token: token,
            customeridstr: cud_ids
          },
          success: function(msg) {
            var emails = "";
            $.each(msg.crmlinkmanlist, function(index, row) {
              var name = row.full_name;
              emails += row.full_name + "<" + row.email + ">" + ",";
            });
            emails = emails.substring(0, emails.length - 1);
            lef.$router.push({
              path: "/mail"
            });
            lef.$store.state.mail.wlPagetype = "";
            lef.$store.state.mail.wlMailid = 0;
            lef.writeLetter({
              name: "客户发信",
              addressee: emails
            });
          }
        });
      } else {
        this.$message({
          showClose: true,
          message: "请选择客户列表！",
          type: "warning",
          duration: 2000
        });
      }
    },
    //群发邮件
    sendYxymail() {
      if (
        this.userparamlist.param30 == undefined ||
        this.userparamlist.param30 == 0
      ) {
        this.$message({
          showClose: true,
          message: "您还未开通营销模块！",
          type: "warning",
          duration: 2000
        });
        return;
      }
      if (this.selectArr.length == 0) {
        this.$message({
          showClose: true,
          message: "请选择客户列表！",
          type: "warning",
          duration: 2000
        });
        return;
      }
      this.yxyProcess = true;
    },
    //确定销售进程
    yxyProcessConfirm() {
      this.yxyProcess = false;
      var lef = this;
      var cus_ids = ""; //客户id
      $.each(lef.selectArr, function(i, row) {
        cus_ids += row + ",";
      });
      cus_ids = cus_ids.substring(0, cus_ids.length - 1);
      if (yxyUrl.indexOf("yxylogin.action") != -1) {
        yxyUrl =
          yxyUrl.substring(0, yxyUrl.lastIndexOf("/") + 1) +
          "webmailsend.action?customerid=" +
          cus_ids +
          "&jinchengid=" +
          lef.yxyProcessId +
          "&token=" +
          token;
      } else {
        yxyUrl =
          yxyUrl +
          "webmailsend.action?customerid=" +
          cus_ids +
          "&jinchengid=" +
          lef.yxyProcessId +
          "&token=" +
          token;
      }
      this.$router.push({
        path: "/yxy"
      });
    },
    //返回全部客户
    returnCrm() {
      this.$emit("returnCrm");
    },
    //录入时间自定义查询
    dateSearch() {
      if (this.dateValue == "") {
        this.$message({
          showClose: true,
          message: "开始时间不能为空！",
          type: "warning",
          duration: 2000
        });
        return;
      }
      //转化开始时间
      var myDate = new Date(this.dateValue);
      var year = myDate.getFullYear().toString();
      var month = (myDate.getMonth() + 1).toString();
      var day = myDate.getDate().toString();
      if (month.length == 1) {
        month = "0" + month;
      }
      if (day.length == 1) {
        day = "0" + day;
      }
      this.dateValue = year + "-" + month + "-" + day;
      //转化结束时间
      if (this.dateValue2 != "") {
        var myDate = new Date(this.dateValue2);
        var year = myDate.getFullYear().toString();
        var month = (myDate.getMonth() + 1).toString();
        var day = myDate.getDate().toString();
        if (month.length == 1) {
          month = "0" + month;
        }
        if (day.length == 1) {
          day = "0" + day;
        }
        this.dateValue2 = year + "-" + month + "-" + day;
      }
      this.cus_currpage = 1;
      this.cuscondition = 13;
      this.cusconditionvalue = this.dateValue + "," + this.dateValue2;
      this.loadCustomerList();
    },
    //联系时间自定义查询
    dateSearch2() {
      if (this.dateValue3 == "") {
        this.$message({
          showClose: true,
          message: "开始时间不能为空！",
          type: "warning",
          duration: 2000
        });
        return;
      }
      //转化开始时间
      var myDate = new Date(this.dateValue3);
      var year = myDate.getFullYear().toString();
      var month = (myDate.getMonth() + 1).toString();
      var day = myDate.getDate().toString();
      if (month.length == 1) {
        month = "0" + month;
      }
      if (day.length == 1) {
        day = "0" + day;
      }
      this.dateValue3 = year + "-" + month + "-" + day;
      //转化结束时间
      if (this.dateValue4 != "") {
        var myDate = new Date(this.dateValue4);
        var year = myDate.getFullYear().toString();
        var month = (myDate.getMonth() + 1).toString();
        var day = myDate.getDate().toString();
        if (month.length == 1) {
          month = "0" + month;
        }
        if (day.length == 1) {
          day = "0" + day;
        }
        this.dateValue4 = year + "-" + month + "-" + day;
      }
      this.cus_currpage = 1;
      this.cuscondition = 19;
      this.cusconditionvalue = this.dateValue3 + "," + this.dateValue4;
      this.loadCustomerList();
    },
    //成交记录自定义查询
    numSearch() {
      if (this.numValue < 0 || this.numValue2 < 0) {
        this.$message({
          showClose: true,
          message: "成交记录数只能输入正整数！",
          type: "warning",
          duration: 2000
        });
        return;
      }
      if (this.numValue > this.numValue2) {
        this.$message({
          showClose: true,
          message: "最少成交记录数不能大于最多成交记录数！",
          type: "warning",
          duration: 2000
        });
        return;
      }
      this.cus_currpage = 1;
      this.cuscondition = 40;
      this.cusconditionvalue = this.numValue + "," + this.numValue2;
      this.loadCustomerList();
    },
    //成交金额自定义查询
    numSearch2() {
      if (this.numValue3 < 0 || this.numValue4 < 0) {
        this.$message({
          showClose: true,
          message: "成交金额数只能输入正整数！",
          type: "warning",
          duration: 2000
        });
        return;
      }
      if (this.numValue3 > this.numValue4) {
        this.$message({
          showClose: true,
          message: "最少成交金额数不能大于最多成交金额数！",
          type: "warning",
          duration: 2000
        });
        return;
      }
      this.cus_currpage = 1;
      this.cuscondition = 41;
      this.cusconditionvalue = this.numValue3 + "," + this.numValue24;
      this.loadCustomerList();
    },
    //查看客户详细信息
    openCustomerInfo(cus_id, uid) {
      this.customer_id = cus_id;
      this.hasEditbtn = false;
      this.userId = uid;
      this.showN = true;
      this.$store.commit("FROM_COUNTRY_EDIT", true);
    },
    //获取当前选中的国家地区
    getCountryValue(val) {
      $(".opr-btn").removeClass("selectFilter"); //移除样式
      this.cus_currpage = 1;
      this.cuscondition = 56;
      this.cusconditionvalue = val.toString();
      this.loadCustomerList();
      this.getShowCountry();
    },
    //清除当前选中的所在国家的选项
    cancelCountryCheck() {
      this.cuscondition = 56;
      this.cusconditionvalue = "";
      if (this.checkList.length != 0) {
        this.loadCustomerList();
      }
      this.checkListShow = [];
      this.checkList = [];
    },
    //点击图标编辑所在国家
    editCountryInfo(value, id) {
      this.editcountryBox = true;
      this.editCusId = id;
      this.$store.commit("CUR_COUNTRY", value);
      this.$store.commit("FROM_COUNTRY_EDIT", false);
    },
    //确认国家的选择
    confirmCountry() {
      let name = $("#os_name").val();
      if (name == "") {
        this.$message({
          message: "请输入所在国家！",
          type: "warning"
        });
        return;
      }
      let self = this;
      //判断是否修改国家
      if (self.$store.state.curCountry == name) {
        self.reduceCountry();
        return;
      }
      $.ajax({
        type: "POST",
        url: backUrl + "cus_updateCustomerCountry.action",
        data: {
          token: token,
          customer_id: this.editCusId,
          str: name
        },
        success: function(res) {
          if (res == 1) {
            self.$message({
              message: "所在国家操作成功！",
              type: "success"
            });
            self.loadCustomerList();
          } else {
            self.$message({
              message: "所在国家操作失败！",
              type: "error"
            });
          }
          self.reduceCountry();
        }
      });
    },
    //取消国家的编辑
    reduceCountry() {
      this.editcountryBox = false;
      this.editCusId = 0;
    },
    //查看更多
    lookMoreCountry() {
      this.lookCheckList = JSON.parse(JSON.stringify(this.checkList));
      this.lookCountryBox = true;
    },
    //确认查看
    confirmCountry2() {
      this.cuscondition = 56;
      this.checkList = JSON.parse(JSON.stringify(this.lookCheckList));
      this.cusconditionvalue = this.checkList.toString();
      if (this.checkList.length != 0) {
        this.loadCustomerList();
      }
      this.getShowCountry();
      this.reduceCountry2();
    },
    //取消查看
    reduceCountry2() {
      this.lookCountryBox = false;
      this.lookCheckList = [];
    },
    //获取所在国家
    getShowCountry() {
      //获取当前选择的国家
      let self = this;
      self.checkListShow = [];
      $.each(self.checkList, function(b, len) {
        $.each(self.$store.state.countryArr, function(i, iLen) {
          if (iLen.name == len) {
            self.checkListShow.push(iLen);
            return false;
          }
        });
      });
    },
    //员工填写时编辑客户编码
    editUserCode(id) {
      let self = this;
      //判断当前是员工填写
      if (this.userCodeType) {
        $.ajax({
          type: "POST",
          url: backUrl + "cus_updateCustomerCodeByComapny.action",
          data: {
            token: token,
            customer_id: id,
            param: this.user237Value
          },
          beforeSend: function() {
            self.autographLoading = true;
          },
          complete: function() {
            self.autographLoading = false;
          },
          success: function(res) {
            if (res == 1) {
              self.$message({
                message: "操作成功！",
                type: "success"
              });
              self.loadCustomerList();
            } else if (res == 0) {
              self.$message({
                message: "操作失败！",
                type: "warning"
              });
            } else if (res == -1) {
              self.$message({
                message: "该客户编号已存在！",
                type: "warning"
              });
            }
          }
        });
      } else {
        this.$prompt("请输入客户编号", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          cancelButtonClass: "cancelButtonClass"
        })
          .then(({ value }) => {
            $.ajax({
              type: "POST",
              url: backUrl + "cus_updateCustomerCodeByUser.action",
              data: {
                token: token,
                customer_id: id,
                code: value
              },
              beforeSend: function() {
                self.autographLoading = true;
              },
              complete: function() {
                self.autographLoading = false;
              },
              success: function(res) {
                if (res == 1) {
                  self.$message({
                    message: "操作成功！",
                    type: "success"
                  });
                  self.loadCustomerList();
                } else if (res == 0) {
                  self.$message({
                    message: "操作失败！",
                    type: "warning"
                  });
                } else if (res == -1) {
                  self.$message({
                    message: "该客户编号已存在！",
                    type: "warning"
                  });
                }
              }
            });
          })
          .catch(() => {});
      }
    },
    //获取237的值
    getPar237value() {
      if (this.$store.state.companyparamlists == "") {
        this.$store.commit("getCompanyParamLists");
      }
      let self = this;
      $.each(this.$store.state.companyparamlists, function(i, row) {
        if (row.para_id == 237) {
          if (
            row.para_value != "no" &&
            row.para_value != null &&
            row.para_value != undefined &&
            row.para_value != ""
          ) {
            //返回值为0则是员工填写
            if (parseInt(row.para_value) == 0) {
              self.userCodeType = false;
            } else {
              self.userCodeType = true;
              self.user237Value = row.para_value;
            }
          } else {
            self.userCodeType = false;
          }
          return false;
        }
      });
    },

    //一键ERP
    modifyAllERP(ids) {
      let self = this;
      let noCode = false;
      if (ids !== undefined) {
        this.selectArr = ids;
      }
      if (this.selectArr.length == 0) {
        this.$message({
          showClose: true,
          message: "请选择客户列表！",
          type: "warning",
          duration: 2000
        });
        return;
      }
      for (let i = 0, len = this.selectArr.length; i < len; i++) {
        for (let j = 0; j < this.customerList.length; j++) {
          if (this.customerList[j].customer_id == this.selectArr[i]) {
            if (
              this.customerList[j].userCode === undefined ||
              this.customerList[j].userCode === null ||
              this.customerList[j].userCode == "无"
            ) {
              noCode = true;
              break;
            }
          }
        }
        if (noCode) {
          break;
        }
      }
      if (noCode) {
        this.$message({
          message: "选择的客户必须都要填写客户编号！",
          type: "warning"
        });
        return;
      }
      $.ajax({
        type: "POST",
        url: backUrl + "erp_addMyCustomerManyToErp.action",
        cache: false,
        async: false,
        data: {
          token: token,
          customer_ids: this.selectArr.join()
        },
        success: function(res) {
          if (res == 1) {
            self.$message({
              message: "操作成功！",
              type: "success"
            });
          } else if (res == 0) {
            self.$message({
              message: "操作失败！",
              type: "warning"
            });
          }
          self.selectArr = [];
        }
      });
    }
  },
  computed: {
    ...mapState({
      userinfo: state => state.userinfo,
      customerclassifyall: state => state.customerclassifyall,
      customerfromall: state => state.customerfromall,
      customerrelationall: state => state.customerrelationall,
      customerstatusall: state => state.customerstatusall,
      industryType: state => state.hangyeArr,
      majorproductall: state => state.majorproductall,
      necessaryitemall: state => state.necessaryitemall,
      countryArr: state => state.countryArr,
      userparamlist: state => state.userparamlist,
      companyparamlist: state => state.companyparamlist,
      userlistall: state => state.userlistall,
      daySurplusCusCounts: state => state.daySurplusCusCounts,
      saleprocessall: state => state.saleprocessall,
      loadCusNumTag: state => state.loadCusNumTag,
      skipCusId: state => state.skipCusId,
      plicencefun: state => state.plicencefun,
      productlistall2: state => state.productlistall2,
      pmail_userlablenum: state => state.pmail_userlablenum,
    })
  },
  filters: {
		getValue(value) { //无数据的时候返回值
			if (value == '' || value === null || value == undefined || value == 0) {
				return "无"
			}else if(value.length > 15){
				return value.substring(0,15)+"...";
			} else {
				return value
			}
		},
	},
  watch: {
    //表格定位
    itemNum(newv) {
      if (newv != -1) {
        $(".newTableStyle").css("top", "196px");
      } else {
        $(".newTableStyle").css("top", "160px");
      }
    },
    bigType(newv) {
      if (this.bigType == 1) {
        //只有客户分类展开时才加载客户
        this.cuscondition = 0;
      } else {
        this.cuscondition = this.bigType;
      }
      this.itemNum = -1;
      $(".opr-btn").removeClass("selectFilter");
      this.$emit("listFilter5");
      this.cus_currpage = 1;
      this.cusconditionvalue = this.bigTypeValue;
      if (newv == 0 || newv == 1) {
        this.loadCustomerList();
      }
    },
    bigTypeValue(newv) {
      this.itemNum = -1;
      $(".opr-btn").removeClass("selectFilter");
      this.$emit("listFilter5");
      this.cus_currpage = 1;
      this.cuscondition = this.bigType;
      this.cusconditionvalue = this.bigTypeValue;
      this.loadCustomerList();
      //				if(newv != 0) {
      //					this.loadCustomerList();
      //				}
    },
    processid() {
      this.itemNum = -1;
      $(".opr-btn").removeClass("selectFilter");
      this.$emit("listFilter5");
      this.cus_currpage = 1;
      this.cuscondition = 34;
      this.cusconditionvalue = this.processid;
      this.loadCustomerList();
    },
    //获取产品列表
    getProductList(){

    }
  },
  activated() {
    this.showN = false;
    //判断当前客户编码的类型
    this.getPar237value();
  },
  mounted() {
    //			  	$('#routerLoading').css('display', 'block');
    //加载客户分类信息
    if (this.customerclassifyall == "") {
      this.getCustomerClassifyAll();
    }
    //加载客户来源信息
    if (this.customerfromall == "") {
      this.getCustomerFromAll();
    }
    //加载客户关系信息
    if (this.customerrelationall == "") {
      this.getCustomerRelationAll();
    }
    //加载客户状态
    if (this.customerstatusall == "") {
      this.getCustomerStatusAll();
    }
    //加载自定义标签
    if(this.pmail_userlablenum == ""){
    	this.fun_userlablenumlist();
    }
    //加载关联产品信息
    if (this.productlistall2 == "") {
      this.getProductlistAll2({
				currpage: this.p_currpage,
				pagesize: this.p_pagesize,
				conditionValue: this.p_input
			});
    }
    if (this.productlistall2 != "" && this.productlistall2 != null)
    {
      this.mainProductInfo = this.productlistall2.productclassifylist;
    	this.p_tolRecord = this.productlistall2.pageBean.totalRecord;
    	this.pageCount = this.productlistall2.pageBean.totalPage;
    	for(var i = 0;i < this.mainProductInfo.length;i++){
    		if(i < 5){
    			this.pageProductList.push(this.mainProductInfo[i]);
    		}
    	}
    } else {
    	this.pageProductList = [];
      this.mainProductInfo = [];
      this.p_tolRecord = 0;
      this.pageCount = 1;
    }
    //加载销售进程
    if (this.saleprocessall == "") {
      this.getSaleProcessAll();
    }
    var self = this;
    $.each(this.saleprocessall, function(i, row) {
      var name1 = row.name;
      var name2 = row.name;
      if (name1.length > 5) {
        name1 = name1.substring(0, 5) + "...";
      }
      if (name2.length > 12) {
        name2 = name2.substring(0, 12) + "...";
      }
      self.salesProcessList.push({
        id: row.id,
        name: row.name,
        name1: name1,
        name2: name2
      });
    });
    //加载所有用户
    if (this.userlistall == "") {
      this.getUserlistAll();
    }
    if (this.skipCusId > 0) {
      this.cuscondition = 39;
      this.cusconditionvalue = this.skipCusId;
      this.$store.state.skipCusId = 0;
    }
    if (noFollowParam == 1) {
      this.cuscondition = 54;
      noFollowParam = 0;
    }
    this.loadCustomerList(); //加载页面数据
    if (this.userinfo.param_level == 0) {
      //企业级显示转移客户|共享客户|加入公海操作
      this.showOperation = true;
    } else {
      //个人级隐藏转移客户|共享客户|加入公海操作
      this.showOperation = false;
    }
    dialogDrag();
    dialogCenter();
    //点击顶部操作栏的各筛选功能进行的active切换
    let _this = this;
    $.each(_this.userlistall, function(i, user) {
      if (user.user_id != _this.userinfo.user_id) {
        //不包括当前用户
        _this.clientList.push({
          user_id: user.user_id,
          name: user.user_name
        });
      }
    });
    //导出
    this.showSystemAdmin = false;
    if (this.userinfo.user_role == 1) {
      this.showSystemAdmin = true;
    }

    (function() {
      function a() {
        $(".opr-btn.condition-btn").on("click", function() {
          _this.filterNum.num = 0;
          for (var i = 0; i < $(".opr-btn.condition-btn").length; i++) {
            $(".opr-btn.condition-btn").removeClass("selectFilter");
            $(this).addClass("selectFilter");
          }
        });
      }
      a();
    })();

    //判断当前客户编码的类型
    this.getPar237value();
  }
};
</script>
<style src="../../../css/crm/allClient.css"></style>
<style src="@/css/common.css"></style>
<style scoped="">
.newTableStyle {
  /* position: absolute;
  top: 118px;
  bottom: 50px; */
  overflow: auto;
}
.crm-country {
  display: flex;
  align-items: center;
  transition: all 0.3s linear;
  margin-right: 15px;
}
.crm-country .countryFlag {
  cursor: pointer;
  transition: all 0.3s linear;
}
/* .crm-country .countryFlag:hover{
		transform: scale(1.2);
	} */
.crm-country span {
  margin-right: 0px;
}
.crm-country-more {
  color: #20a0ff;
  border-radius: 2px;
  padding: 2px 5px;
  cursor: pointer;
  line-height: 20px;
  height: 22px;
}
.crm-country-more:hover {
  background: #20a0ff;
  color: #fff;
}
.elCountryC .el-checkbox__label {
  padding-left: 0px;
}
.countryPoint {
  cursor: pointer;
}
.width280 {
  width: 200px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.newTableStyle .disFlex{
  display: flex
}

.labelTag{
	width: 36px;
	font-size:12px;
	padding:1px;
	margin-bottom:0;
	cursor: pointer;
	text-overflow: ellipsis;
	text-align: center;
	color: #fff;
  max-width: 80px;
}

</style>
