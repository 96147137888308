<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·关联产品
开始时间：2018-12-08
开发人员：陈立珍
最后修改：2018-12-08
备注说明：如需修改请联系开发人员
-->

<template>
	<div class="reProSearch">
		<el-dialog title="选择关联产品" class='dialogThree' size="tiny" :visible.sync="proTest" :before-close="closePro" :modal="false" :modal-append-to-body="true" :lock-scroll="true" :close-on-click-modal="false">
			<popupLoading></popupLoading>
			<!-- 表格 -->
			<div class="search-result">
				<table width="100%" cellspacing="0">
					<thead>
						<tr>
							<th width="20%">序号</th>
							<th width="25%">产品名称</th>
							<th width="15%">选择</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item,index) in proInfos">
							<td>
								<span>{{index+1}}</span>
							</td>
							<td>
								<div class="show-font">
									<el-tooltip class="item" effect="dark" :open-delay="300" :content="item.name" placement="top">
										<span>{{item.name}}</span>
									</el-tooltip>
								</div>
								
							</td>
							<td>
								<el-radio-group v-model="choosePro">
								    <el-radio :label="item.id"></el-radio>
								  </el-radio-group>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitProBtn()">确 定</el-button>
				<el-button @click="closePro">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import popupLoading from '@/components/publicComponent/popupLoading'
	import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
	export default({
		props: ['major_productall','mainProId'],
		data() {
			return {
				proTest: true, //弹窗
				choosePro: "", //选中的关联产品
				proInfos:[],
			}
		},
		components: {
			popupLoading
		},
		methods: {
			//触发父组件关闭弹窗
			closePro: function() {
				this.$emit('closePro');
			},
			//确认产品
			submitProBtn(){
				if(this.choosePro == ""){
					this.$message({
						showClose: true,
						message: '请选择关联产品!',
						type: 'warning',
						duration: 2000
					});
					return;
				}
				this.$emit('submitProBtn',this.choosePro)
			}
			
		},
		mounted: function() {
			this.choosePro = this.mainProId;
			this.proInfos = this.major_productall;
			
			function clearTransform() {
				$('.el-dialog').css({
					'transform': 'none',
				})
			};
			dialogDrag();
			clearTransform(dialogCenter());
		}
	})
</script>

<style>
	.reProSearch .el-dialog {
		width: 680px;
		height: auto;
	}
	
	.reProSearch .el-dialog__body {
		text-align: left;
		clear: both;
		color: #48576a;
		overflow: auto !important;
	}
	
	.reProSearch .search-result {
		margin-top: 10px;
		height: 360px;
		overflow: auto;
	}
	
	.reProSearch .search-result th,
	.reProSearch .search-result td {
		text-align: center;
		font-weight: normal;
	}
	
	.reProSearch .search-result th {
		background-color: #ededed;
		border-bottom: 1px solid #ededed !important;
		height: 30px;
	}
	
	.reProSearch .search-result td {
		height: 45px;
		border-bottom: 1px solid #ededed !important;
	}
	
	.reProSearch .show-font{
		max-width: 390px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.reProSearch .search-result .el-radio__label{
		display: none;
	}
</style>