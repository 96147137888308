<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·添加新客户·导入记录弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-11-12
备注说明：如需修改请联系开发人员
-->

<template>
  <div id="importRecords">
    <div>
      <div>
        <table width="100%" cellpadding="0" cellspacing="0" border="0" class="crm-records-tab" 
        	v-loading.body="autographLoading" element-loading-text="拼命加载中">
          <thead>
            <tr>
              <th width="5%"></th>
              <th width="25%" >上传时间</th>
              <th width="20%" >导入类型</th>
              <th width="25%" >状态</th>
              <th width="25%" >处理结果</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="record in recordlists">
              <td width="5%" ></td>
              <td width="25%" >{{record.uptime}}</td>
              <td width="20%" >{{record.uptype}}</td>
              <td width="25%" v-if="record.upstatus==1">
                已处理
              </td>
              <td width="25%" v-else-if="record.upstatus==0">
                未处理
              </td>
              <td width="25%" v-else>
                处理中
              </td>
              <td width="25%" v-if="record.upstatus==1">
                <a href="javascript:void(0)" @click="openAlert(record.upid)">查看</a>
              </td>
              <td width="25%" v-else>
                --
              </td>
            </tr>
          </tbody>
        </table>
        <!--暂无数据-->
        <div class="myNoData" v-show='recordlists.length== 0'>
          <p><i class="icon iconfont icon-zanwushuju"></i></p>
          <span>暂无数据</span>
        </div>
        <!--分页-->
        <el-pagination v-show='recordlists.length>0'
          class="paging"
          :current-page.sync="currpage"
          :page-size="6"
          :page-count='tolpage'
          :total="tolRecord"
          layout="total,prev, pager, next"
          @current-change='findImportRecord()'>
        </el-pagination>
      </div>
    </div>
    <!--<transition name="bounce">-->
		<!--引入导入客户处理结果组件-->
       <commonAlert :getupid='upid'
        v-if="alertShow"
        @closeAlert = 'closeAlert'></commonAlert>
    <!--</transition>-->
  </div>
</template>
<script>
  import commonAlert from "./createClient_imptRecord_look.vue"
  export default({
    name:'importRecords',
    data(){
      return{
        upid:0,//记录id
        currpage:1,//当前页
        tolpage:1,//总页数
        tolRecord:1,//总条数
        alertShow:false,
        recordlists:[ ],//导入记录列表
        autographLoading:false,//true选择框显示
      }
    },
    components:{
      commonAlert
    },
    methods:{
    	//查看客户导入的结果弹窗
      openAlert:function(upid){
        this.upid = upid;
        this.alertShow = true
      },
      //关闭查看导入的结果
      closeAlert:function(m){
        this.alertShow = m
      },
      //导入记录
      findImportRecord() {
        let self = this;
        $.ajax({
          type: "POST",
          url: backUrl + "cus_findTimerImportCustomers.action",
          dataType: "json",
          data: {
            token: token,
            currpage: this.currpage,
            pagesize: 6
          },
          cache: false,
          async: false,
          beforeSend : function() {
            this.autographLoading=true;
          },
          complete : function() {
            this.autographLoading=false;
          },
          success: function(msg) {
            self.tolpage = msg.pageBean.totalPage; //总页数  
            self.tolRecord = msg.pageBean.totalRecord; //总条数
            self.recordlists = []
            $.each(msg.timerImportCustomers, function(index, row) {
              let uptype="普通导入", upstatus="处理中";
              if(row.import_type==0){
                uptype="普通导入";
              }else{
                uptype="系统导入";
              }
              self.recordlists.push({
                uptime: row.upload_time,
                uptype: uptype,
                upstatus: row.status,
                upid: row.id,
              })
            });
          }
        });
      }
    },
    mounted(){
      this.findImportRecord();//加载列表信息
    }
  })
</script>
<style rel="stylesheet/scss" lang="less">
  @backGray:#eef1f6;
  #commonAlert .el-dialog__wrapper >.el-dialog{
    width:700px;
  }
  select,input{
    outline:none;
  }
  .crm-records-title{
    text-align: left;
    height:35px;
    line-height: 35px;
  }
  .crm-records-tab{
    overflow: hidden;
    border: 1px solid #d3d3d3;
    border-radius: 2px;
    -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    //margin-bottom:10px;
    tr{
      &:hover{
        background-color:@backGray;
      }
    }
    th,td{
      height:40px;
      text-align: left;
      font-weight: normal;
    }
    th{
      background: @backGray;
      color: #999;
    }
    td{
      border-top: 1px solid #d3d3d3;
      a{
        display: inline-block;
        border-radius: 2px;
        padding: 0 5px;
        height: 30px;
        line-height: 30px;
        font-size:14px;

        color:#20a0ff;
        cursor:pointer;
        &:hover{
           background-color:#20a0ff;
           color:#fff;
        }
      }
    }
  }
  .crm-records-tj_z{
    float: left;
    margin-left: 20px;
  }
  .crm-records-tj_y{
    float: right;
    margin-right: 20px;
    ul{
      li{
        float: left;
        margin-left: 10px;
        color: #1965b6;
        cursor: pointer;
      }
    }
  }
</style>
