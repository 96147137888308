<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·添加新业务弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<template>
	<div id="newFollow" class="newFollow">
		<!--<div class="dialog-masks" v-if="test"></div>-->
		<div class="alertContent">
			<el-dialog title="添加新业务" class='yourName dialogOne' size="tiny" :visible.sync="test" :before-close="closed" :modal="false" :modal-append-to-body="true" :lock-scroll="true" :close-on-click-modal="false">
				<popupLoading></popupLoading>
				<!--此处添加你的弹框内容-->
				<div class="crm-create-box">
					<div class="ct-con-box">
						<!--导航栏新建跟进/新建商机/新建订单-->
						<div class="createTab-title">
							<ul>
								<li v-for="(item,$index) in lists" @click="selectTitle(item,$index)" :class="[titleNum == $index?'select-active':'unselect-active']">
									{{item.text}}
								</li>
							</ul>
						</div>
					</div>
					<!--引入外部页面-->
					<div class="crm-alert-tab">
						<component :followupType="-1" :chanceId="0" :saleId="0" :is="currentTab" @recordok="refresfCustomer" @submitok="closed" ref="tab" :customerids='customerids' :userId="userId" keep-alive></component>
					</div>
				</div>
				<!--底部确认取消按钮-->
				<span slot="footer" class="dialog-footer">
		        	<el-button type="primary" @click="submitInfo">确 定</el-button>
		        	<el-button @click="closed">取 消</el-button>
		      	</span>
			</el-dialog>
		</div>
	</div>
</template>
<script>
	import newBusiness from './../CommonItem/newBusiness.vue' //新建商机
	import newOrders from './../CommonItem/newOrders.vue' //新建订单
	import importRecords from './../createClient/createClient_importRecords.vue' //新建记录
	import newFollow from './../CommonItem/newFollow.vue' //新建跟进
	import popupLoading from '@/components/publicComponent/popupLoading'
	export default({
		name: "newFollow",
		props: [
			'customerids', //客户id,多个以逗号分割
			'userId'
		],
		data() {
			return {
				test: true,
				currentTab: 'tab1',
				titleNum: 0,
				// lists: [{
				// 	text: "新建跟进"
				// }, {
				// 	text: "新建商机"
				// }, {
				// 	text: "新建订单"
				// }]
				lists: [{
					text: "新建跟进"
				}, {
					text: "新建订单"
				}]
			}
		},
		components: {
			tab1: newFollow,
			tab2: newBusiness,
			tab3: newOrders,
			popupLoading
		},
		methods: {
			//导航栏新建跟进/新建商机/新建订单
			selectTitle: function(i, v) {
				this.titleNum = v;
				if(v==2){
					console.log(this.customerids)
				}
				this.currentTab = 'tab' + (v + 1)
			},
			//触发父组件关闭弹窗
			closed() {
				this.$emit('close');
			},
			//提交
			submitInfo: function() {
				if(this.titleNum == 0) { //新建跟进
					this.$refs.tab.subButton(this.customerids);
				} else if(this.titleNum == 1) { //新建商机
					this.$refs.tab.subButton(this.customerids);
				} else if(this.titleNum == 2) { //新建订单
					this.$refs.tab.subButton(this.customerids);
				}
			},
			//添加跟进记录成功返回
			refresfCustomer() {
				this.$emit('addRecord');
				this.$emit('close');
			},
		},
		mounted() {
			dialogCenter();
			dialogDrag();
		}
	})
</script>
<style lang="less">
	.v-modal {
		display: none;
	}
	
	.newFollow {
		.connectInfo {
			width: 250px;
		}
		.el-dialog.el-dialog {
			position: fixed!important;
		}
		/*.el-dialog__wrapper{*/
		/*position:fixed!important;*/
		/*}*/
		.el-dialog {
			z-index: 2002;
			width: 720px;
		}
		.yourName .el-dialog__header {
			line-height: 0;
			background-color: #20A0FF;
			padding: 20px 20px;
			text-align: left;
		}
		/*头部样式*/
		.yourName .el-dialog__header {
			line-height: 0;
			background-color: #20A0FF;
			padding: 20px 20px;
			text-align: left;
		}
		/*字体颜色*/
		.yourName .el-dialog__title,
		.yourName .el-dialog__headerbtn .el-dialog__close,
		.yourName .el-dialog__headerbtn .el-dialog__close:hover {
			color: #fff;
		}
		.el-dialog__headerbtn {
			/*display: none;*/
		}
		.createTab-title {
			overflow: hidden;
			margin-bottom: 10px;
			li {
				line-height: 20px;
				cursor: pointer;
				float: left;
				padding: 6px 10px;
				box-sizing: border-box;
				border: 1px solid #bfcbd9;
				border-right: 0;
				background: #fff;
				color: #1f2d3d;
				-webkit-appearance: none;
				text-align: center;
				box-sizing: border-box;
				outline: 0;
				margin: 0;
				cursor: pointer;
				transition: all .3s cubic-bezier(.645, .045, .355, 1);
				font-size: 14px;
				&:nth-child(1) {
					border-radius: 2px 0 0 2px;
				}
				&:last-child {
					border-right: 1px solid #bfcbd9;
					border-radius: 0px 2px 2px 0px;
				}
			}
			.select-active {
				color: #fff;
				border-color: #20a0ff;
				background-color: #20a0ff;
			}
			.unselect-active {
				&:hover {
					color: #20a0ff;
				}
			}
		}
		.el-dialog__body {
			max-height: 400px!important;
			padding: 0;
			font-size: 12px;
			overflow: auto;
		}
	}
</style>